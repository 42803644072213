import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Collapse } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classname from 'classnames';
import { PATH_APP } from '~/routes/paths';
//i18n
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import WithPermission from '~/components/Common/WithPermission';
import WithOrPermission from '~/components/Common/WithOrPermission';
import POLICY, { CORE_ACTION, GROUP_POLICY } from '~/constants/policy';
import {
  useMoveElementWhenOverFlow,
  useOutInterView
} from '~/hooks/useInterViewNarbav';
const Navbar = (props) => {
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [whBill, setWhBill] = useState(false);
  const [report, setReport] = useState(false);
  const [whSetting, setWhSetting] = useState(false);
  const [order, setOrder] = useState(false);
  const [warehouse, setWareHouse] = useState(false);
  const [history, setHistory] = useState(false);
  const [branch, setBranch] = useState(false);
  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById('navigation');
    var listActive = ul.getElementsByClassName('active');
    // listActive.forEach(element => {
    //   element.classList.remove('active');
    // });
    for (var i = 0; i < listActive.length; ++i) {
      listActive[i].classList.remove('active');
    }

    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      const isMatchPath = props.location.pathname === items[i].pathname;
      const isMatchSubpath =
        items[i].pathname !== '/' &&
        props.location.pathname.indexOf(items[i].pathname) >= 0;
      if (isMatchPath && isMatchSubpath) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  useOutInterView();
  useMoveElementWhenOverFlow();
  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  }
  return (
    <React.Fragment>
      <div className="container-fluid topnav-wraper">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
              {/* <WithPermission permission={POLICY.READ_WORKFLOW}> */}
                  <li className="nav-item">
                    <Link to={PATH_APP.workFlow.root} className="nav-link">
                      <i className="uil uil-book-reader "></i>{' '}
                      {props.t('Quản lý công việc')}
                    </Link>
                  </li>
                {/* </WithPermission> */}
                <WithOrPermission
                  permission={
                    GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WORLDHEALTH
                  }
                >
                  <li className="nav-item dropdown">
                    <Link
                      to="1"
                      onClick={(e) => {
                        e.preventDefault();
                        // window.location.reload();
                        // setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="uil-apps me-2"></i>
                      {props.t('WorldHealth')}{' '}
                      <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu', { show: app })}>
                      <WithPermission permission={POLICY.READ_WHCHARTDASHBOARDHEALTHCARE}>
                        <Link
                          to={'/dashboard'}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Tổng quan')}
                        </Link>
                      </WithPermission>
                      <WithOrPermission permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WHBILL}>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setWhBill(!whBill);
                            }}
                          >
                            {props.t('Đơn hàng')}{' '}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: whBill
                            })}
                          >
                            <WithPermission permission={POLICY.READ_WHBILL}>
                              <Link
                                to={PATH_APP.whBill.root}
                                className="dropdown-item"
                              >
                                {props.t('Tất cả đơn hàng')}
                              </Link>
                            </WithPermission>

                            <WithPermission
                              permission={POLICY.READ_WHAPPOINTMENT}
                            >
                              <Link
                                to={PATH_APP.whBill.processing}
                                className="dropdown-item"
                              >
                                {props.t('Lịch hẹn đang xử lý')}
                              </Link>
                            </WithPermission>

                            <WithPermission permission={POLICY.READ_QUOTATIONS}>
                              <Link
                                to={PATH_APP.whBill.orderTemp}
                                className="dropdown-item"
                              >
                                {props.t('Tạo đơn hàng tạm')}
                              </Link>
                            </WithPermission>

                            <WithPermission
                              permission={
                                POLICY.READ_WHAPPOINTMENTCANCELPARTNER
                              }
                            >
                              <Link
                                to={PATH_APP.partnerCancelAppointment.root}
                                className="dropdown-item"
                              >
                                {props.t('Yêu cầu huỷ cuộc hẹn')}
                              </Link>
                            </WithPermission>

                            <WithPermission
                              permission={
                                POLICY.READ_WHBILL
                              }
                            >
                              <Link
                                to={PATH_APP.whBill.depreciation}
                                className="dropdown-item"
                              >
                                {props.t('Khấu hao đơn hàng')}
                              </Link>
                            </WithPermission>
                          </div>
                        </div>
                      </WithOrPermission>
                      <WithOrPermission
                        permission={
                          GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WHPARTNER
                        }
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {props.t('Đối tác')}
                            <div className="arrow-down"></div>
                          </Link>
                          <div className={classname('dropdown-menu', {})}>
                            <WithPermission permission={POLICY.READ_WHPARTNER}>
                              <Link
                                to={PATH_APP.whPartner.root}
                                className="dropdown-item"
                              >
                                {props.t('Danh sách đối tác')}
                              </Link>
                            </WithPermission>

                            <WithPermission
                              permission={POLICY.READ_POTENTIALPARTNER}
                            >
                              <Link
                                to={PATH_APP.whPartnerImport.root}
                                className="dropdown-item"
                              >
                                {props.t('Thêm đối tác')}
                              </Link>
                            </WithPermission>
                          </div>
                        </div>
                      </WithOrPermission>
                      <WithOrPermission
                        permission={[
                          POLICY.READ_WHVOUCHERS,
                          POLICY.READ_WHREVENUEPARTNER,
                          POLICY.READ_WHREVENUECUSTOMER,
                          POLICY.READ_WHREVENUESYSTEM,
                          POLICY.READ_WHTRANSACTION,
                          POLICY.READ_WHREQUESTVOUCHER
                        ]} // FIXME: double check the policy
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setReport(!report);
                            }}
                          >
                            {props.t('Báo cáo')}{' '}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: report
                            })}
                          >
                            <WithPermission
                              permission={POLICY.READ_WHREVENUESYSTEM}
                            >
                              <Link
                                to={PATH_APP.whReport.system}
                                className="dropdown-item"
                              >
                                {props.t('Doanh thu hệ thống')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHREVENUEPARTNER}
                            >
                              <Link
                                to={PATH_APP.whReport.partner}
                                className="dropdown-item"
                              >
                                {props.t('Doanh thu đối tác')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHREVENUECUSTOMER}
                            >
                              <Link
                                to={PATH_APP.whReport.customer}
                                className="dropdown-item"
                              >
                                {props.t('Doanh thu khách hàng')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHTRANSACTION}
                            >
                              <Link
                                to={PATH_APP.whTransaction.root}
                                className="dropdown-item"
                              >
                                {props.t('Giao dịch')}
                              </Link>
                            </WithPermission>
                            <WithPermission permission={POLICY.READ_WHVOUCHERS}>
                              <Link
                                to={PATH_APP.whVoucher.root}
                                className="dropdown-item"
                              >
                                {props.t('Sổ quỹ')}
                              </Link>
                            </WithPermission>
                          
                            <WithPermission
                              permission={POLICY.READ_WHREQUESTVOUCHER}
                            >
                              <Link
                                to={PATH_APP.referral.RequestVoucher}
                                className="dropdown-item"
                              >
                                {props.t('Yêu cầu rút tiền')}
                              </Link>
                            </WithPermission>
                          </div>
                        </div>
                      </WithOrPermission>

                      {/* Warehouse */}

                      <WithOrPermission permission={[POLICY.READ_WAREHOUSE , POLICY.READ_PRODUCTDELIVERY,POLICY.ADMIN_PRODUCTDELIVERY ]}>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setWareHouse(!warehouse);
                            }}
                          >
                            {props.t('Kho')} <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: warehouse
                            })}
                          >
                            <Link
                              to={PATH_APP.listWareHouse.root}
                              className="dropdown-item"
                            >
                              {props.t('Quản lý kho')}
                            </Link>
                            <Link
                              to={PATH_APP.medicalEquipments.root}
                              className="dropdown-item"
                            >
                              {props.t('Danh mục thiết bị y tế')}
                            </Link>

                            <Link
                              to={PATH_APP.productCatalogue.root}
                              className="dropdown-item"
                            >
                              {props.t('Danh mục nhóm sản phẩm')}
                            </Link>

                            <Link
                              to={PATH_APP.productList.root}
                              className="dropdown-item"
                            >
                              {props.t('Danh sách sản phẩm')}
                            </Link>

                            <Link
                              to={PATH_APP.voucherWareHouse.root}
                              className="dropdown-item"
                            >
                              {props.t('Quản lý phiếu bàn giao')}
                            </Link>
                            <Link
                              to={PATH_APP.deliveryCoupon.root}
                              className="dropdown-item"
                            >
                              {props.t('Quản lý phiếu luân chuyển')}
                            </Link>

                            <Link
                              to={PATH_APP.confirmVoucherWareHouse.root}
                              className="dropdown-item"
                            >
                              {props.t('Quản lý phiếu xác nhận')}
                            </Link>
                          </div>
                        </div>
                      </WithOrPermission>

                      {/* END WAREHOUSE */}

                      <WithOrPermission
                        permission={
                          GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WHSETTING
                        }
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setWhSetting(!whSetting);
                            }}
                          >
                            {props.t('Cài đặt')}{' '}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            style={{ overflowY: 'scroll', maxHeight: '380px' }}
                            className={classname(
                              'dropdown-menu dropdown-menu-setting ',
                              {
                                show: whSetting
                              }
                            )}
                          >
                            <WithPermission
                              permission={POLICY.READ_WHSERVICEGROUP}
                            >
                              <Link
                                to={PATH_APP.whCategory.root}
                                className="dropdown-item"
                              >
                                {props.t('Nhóm dịch vụ')}
                              </Link>
                            </WithPermission>
                            <WithPermission permission={POLICY.READ_WHSERVICE}>
                              <Link
                                to={PATH_APP.whService.root}
                                className="dropdown-item"
                              >
                                {props.t('Dịch vụ')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHTIMEREGULATION}
                            >
                              <Link
                                to={PATH_APP.whSessionOfDay.root}
                                className="dropdown-item"
                              >
                                {props.t('Quy định thời gian')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHCREDENTIAL}
                            >
                              <Link
                                to={PATH_APP.rateConfig.degreeList}
                                className="dropdown-item"
                              >
                                {props.t('Bằng cấp')}
                              </Link>
                            </WithPermission>
                            <WithPermission permission={POLICY.READ_WHPOSITION}>
                              <Link
                                to={PATH_APP.rateConfig.position}
                                className="dropdown-item"
                              >
                                {props.t('Chức vụ')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHEXPERIENCE}
                            >
                              <Link
                                to={PATH_APP.rateConfig.experience}
                                className="dropdown-item"
                              >
                                {props.t('Kinh nghiệm')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHPROFESSIONALSTAFF}
                            >
                              <Link
                                to={PATH_APP.tableRanking.root}
                                className="dropdown-item"
                              >
                                {props.t('Cán bộ chuyên môn')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHJOBSTANDARD}
                            >
                              <Link
                                to={PATH_APP.whWorkNorm.root}
                                className="dropdown-item"
                              >
                                {props.t('Định mức công việc')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHCUSTOMEREVALUATION}
                            >
                              <Link
                                to={PATH_APP.evaluate.root}
                                className="dropdown-item"
                              >
                                {props.t('Khách hàng đánh giá')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={
                                POLICY.READ_WHSERVICEBOOKINGINSTRUCTIONSSETUP
                              }
                            >
                              <Link
                                to={PATH_APP.serviceBookingInstruction.root}
                                className="dropdown-item"
                              >
                                {props.t('Set up Hướng dẫn đặt dịch vụ')}
                              </Link>
                            </WithPermission>

                            <WithPermission
                              permission={POLICY.READ_WHCOURSETRAINING}
                            >
                              <Link
                                to={PATH_APP.whTrainingCourse.root}
                                className="dropdown-item"
                              >
                                {props.t('Quản lý Khoá đào tạo')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_WHMEDICALEQUIPMENT}
                            >
                              <Link
                                to={PATH_APP.whMedicines.root}
                                className="dropdown-item"
                              >
                                {props.t('Thiết bị y tế')}
                              </Link>
                            </WithPermission>

                            <WithPermission
                              permission={POLICY.READ_WHHEALTHCAREFACILITY}
                            >
                              <Link
                                to={PATH_APP.servicePlace.root}
                                className="dropdown-item"
                              >
                                {props.t('Nơi thực hiện dịch vụ')}
                              </Link>
                            </WithPermission>
                            
                            <WithPermission permission={POLICY.READ_WHHEALTHCAREFACILITY}>
                              <Link
                                to={PATH_APP.hospitalList.root}
                                className="dropdown-item"
                              >
                                {props.t('Danh sách bệnh viện')}
                              </Link>
                            </WithPermission>
                            
                          </div>
                        </div>
                      </WithOrPermission>

                      {/* HISTORY */}
                      <WithPermission permission={POLICY.READ_HISTORY}>
                        <div className="dropdown">
                              <Link
                                to={PATH_APP.history.root}
                                className="dropdown-item"
                              >
                                  {props.t('Lịch sử')}{' '}
                              </Link>
                        </div>
                      </WithPermission>
                    </div>
                  </li>
                </WithOrPermission>
                <WithOrPermission
                  permission={[POLICY.READ_APPOINTMENT, POLICY.READ_PARTNERCLINIC, POLICY.READ_AFFILIATEDAPPOINTMENT, POLICY.READ_CHARTCLINIC]}
                >
                  <li className="nav-item dropdown">
                    <Link
                      to="2"
                      onClick={(e) => e.preventDefault()}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="fas fa-file-invoice me-2"></i>
                      {props.t('Phòng khám')} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu')}>
                      <WithPermission permission={POLICY.READ_CHARTCLINIC}>
                        <Link
                          to={PATH_APP.clinicDashboard.root}
                          className="dropdown-item"
                        >
                          {props.t('Tổng quan')}
                        </Link>
                      </WithPermission>
                      <WithOrPermission
                        permission={[
                          POLICY.READ_APPOINTMENT,
                          POLICY.READ_AFFILIATEDAPPOINTMENT
                        ]}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {props.t('Lịch hẹn')}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: app
                            })}
                          >
                            <WithPermission
                              permission={POLICY.READ_APPOINTMENT}
                            >
                              <Link
                                to={PATH_APP.appointment.root}
                                className="dropdown-item"
                              >
                                {props.t('Danh sách lịch hẹn')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_AFFILIATEDAPPOINTMENT}
                            >
                              <Link
                                to={PATH_APP.associateApointment.root}
                                className="dropdown-item"
                              >
                                {props.t('Lịch hẹn liên kết')}
                              </Link>
                            </WithPermission>
                            <WithPermission
                              permission={POLICY.READ_AFFILIATEDAPPOINTMENT}
                            >
                              <Link
                                to={PATH_APP.appointment.deletedAppointment}
                                className="dropdown-item"
                              >
                                {props.t('Lịch hẹn đã xoá')}
                              </Link>
                            </WithPermission>
                          </div>
                        </div>
                      </WithOrPermission>
                      <WithPermission permission={POLICY.READ_PARTNERCLINIC}>
                        <Link
                          to={PATH_APP.clinics.root}
                          className="dropdown-item"
                        >
                          {props.t('Phòng khám')}
                        </Link>
                      </WithPermission>
                    </div>
                  </li>
                </WithOrPermission>
                <WithOrPermission
                  permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_PHARMACY}
                >
                  <li className="nav-item dropdown">
                    <Link
                      to="3"
                      onClick={(e) => {
                        e.preventDefault();
                        // setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="uil-apps me-2"></i>
                      {props.t('Nhà thuốc Online')}{' '}
                      <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu', { show: app })}>
                      <WithPermission permission={POLICY.READ_ORDER}>
                        <Link
                          to={PATH_APP.pharmacyOnlineDashboard.root}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Tổng quan')}
                        </Link>
                      </WithPermission>
                      <WithPermission permission={POLICY.ORDER}>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setOrder(!order);
                            }}
                          >
                            {props.t('Đơn hàng')}{' '}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: order
                            })}
                          >
                            <Link
                              to={PATH_APP.order.allOrder}
                              className="dropdown-item"
                            >
                              {props.t('Tất cả đơn hàng')}
                            </Link>

                            <Link
                              to={PATH_APP.order.pendingOrder}
                              className="dropdown-item"
                            >
                              {props.t('Đơn hàng đang xử lý')}
                            </Link>
                          </div>
                        </div>
                      </WithPermission>
                      <WithPermission permission={POLICY.READ_PHARMACY}>
                        <Link
                          to={PATH_APP.pharmacies.root}
                          className="dropdown-item "
                        >
                          {props.t('Đối tác')}
                        </Link>
                      </WithPermission>
                    </div>
                  </li>
                </WithOrPermission>
                <WithOrPermission permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_BRANCH}>
                  <li className="nav-item dropdown">
                    <Link
                      to='/#'
                      onClick={(e) => {
                        e.preventDefault();
                        // setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="uil uil-hospital "></i>{' '}
                      {props.t('Chi nhánh')}
                      <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu', { show: app })}>
                      <WithPermission permission={POLICY.READ_BRANCH}>
                        <Link
                          to={PATH_APP.branch.root}
                          className="dropdown-item "
                        >
                          {props.t('Danh sách chi nhánh')}{' '}
                        </Link>
                      </WithPermission>
                      <WithPermission permission={POLICY.READ_SERVICEBRANCH}>
                        <Link
                          to={PATH_APP.whServiceBranch.root}
                          className="dropdown-item "
                        >
                          {props.t('Đăng kí sử dụng dịch vụ')}{' '}
                        </Link>
                      </WithPermission>
                    </div>
                  </li>
                </WithOrPermission>

                {/* <WithOrPermission permission={[POLICY.READ_BRANCH]}>
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        // setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="uil uil-hospital "></i>{' '}
                      {props.t('Chi nhánh')}{' '}
                      <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu', { show: app })}>
                      <WithPermission permission={POLICY.READ_BRANCH}>
                        <Link
                          to={PATH_APP.branch.root}
                          className="dropdown-item "
                        >
                          {props.t('Danh sách chi nhánh')}{' '}
                        </Link>
                      </WithPermission>
                        <Link
                          to={PATH_APP.whServiceBranch.root}
                          className="dropdown-item "
                        >
                          {props.t('Đăng kí sử dụng dịch vụ')}{' '}
                        </Link>
                    </div>
                  </li>
                </WithOrPermission> */}
                <WithPermission permission={POLICY.READ_EMPLOYEE}>
                  <li className="nav-item">
                    <Link to={PATH_APP.employee.root} className="nav-link">
                      <i className="uil uil-users-alt "></i>{' '}
                      {props.t('Nhân viên')}
                    </Link>
                  </li>
                </WithPermission>
                <WithOrPermission
                  permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_USER}
                >
                  <li className="nav-item">
                    <Link to={PATH_APP.user.root} className="nav-link">
                      <i className="uil uil-user"></i> {props.t('Người dùng')}
                    </Link>
                  </li>
                </WithOrPermission>

                {/* THIS PART IS NOT FINISHED YET */}

                <WithOrPermission
                  permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_CUSTOMER}
                >
                  <li className="nav-item dropdown">
                    <Link
                      to="2"
                      onClick={(e) => {
                        e.preventDefault();
                        // setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="uil-apps me-2"></i>
                      {props.t('Quản lý khách hàng')}{' '}
                      <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu', { show: app })}>
                      <WithPermission permission={POLICY.READ_CUSTOMER}>
                        <Link
                          to={PATH_APP.whCustomerManagement.root}
                          className="dropdown-item "
                        >
                          {props.t('Khách hàng')}{' '}
                        </Link>
                      </WithPermission>
                      <WithPermission
                        permission={POLICY.READ_POTENTIALCUSTOMER}
                      >
                        <Link
                          to={PATH_APP.whCustomerImport.root}
                          className="dropdown-item "
                        >
                          {props.t('Import khách hàng')}{' '}
                        </Link>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_CMS}>
                        <Link
                          to={PATH_APP.whContact.root}
                          className="dropdown-item "
                        >
                          {props.t('Liên hệ')}{' '}
                        </Link>
                      </WithPermission>
                    </div>
                  </li>
                </WithOrPermission>
                <WithOrPermission
                  permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_CMS}
                >
                  <li className={`nav-item dropdown `}>
                    <Link
                      to="4"
                      onClick={(e) => {
                        e.preventDefault();
                        // window.location.reload();
                        // setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none "
                    >
                      <i className="uil-apps me-2"></i>
                      {props.t('CMS')} <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname(`dropdown-menu registerOutView`, {
                        show: app
                      })}
                    >
                      <WithPermission permission={POLICY.READ_MEDICALCLINIC}>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setemail(!email);
                            }}
                          >
                            {props.t('Phòng khám')}{' '}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname('dropdown-menu ', {
                              show: email
                            })}
                          >
                            {/* <Link
                            to={PATH_APP.hospital.root}
                            className="dropdown-item"
                          >
                            {props.t('Danh sách phòng khám')}
                          </Link> */}

                            <Link
                              to={PATH_APP.staff.root}
                              className="dropdown-item"
                            >
                              {props.t('Danh sách nhân viên')}
                            </Link>
                          </div>
                        </div>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_ANNOUNCEMENT}>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setui(!ui);
                            }}
                          >
                            {props.t('Tin tức')}{' '}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', { show: ui })}
                          >
                            <Link
                              to={PATH_APP.newsCategory.root}
                              className="dropdown-item"
                            >
                              {props.t('Danh mục tin tức')}
                            </Link>
                            <Link
                              to={PATH_APP.news.root}
                              className="dropdown-item"
                            >
                              {props.t('Danh sách tin tức')}
                            </Link>
                          </div>
                        </div>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_CONFIGURATION}>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setecommerce(!ecommerce);
                            }}
                          >
                            {props.t('Thiết lập')}{' '}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: ecommerce
                            })}
                          >
                            <Link
                              to={PATH_APP.config.degree}
                              className="dropdown-item"
                            >
                              {props.t('Bằng cấp')}
                            </Link>
                            <Link
                              to={PATH_APP.config.speciality}
                              className="dropdown-item"
                            >
                              {props.t('Chuyên môn')}
                            </Link>
                            <Link
                              to={PATH_APP.config.staffGroup}
                              className="dropdown-item"
                            >
                              {props.t('Nhóm nhân viên')}
                            </Link>
                          </div>
                        </div>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_LEADINGPARTNER}>
                        <Link
                          to={PATH_APP.partnership.root}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Đối tác hàng đầu')}{' '}
                        </Link>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_DISCOUNTCODE}>
                        <Link
                          to={PATH_APP.coupon.root}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Mã giảm giá')}{' '}
                        </Link>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_GIVECOUPON}>
                        <Link
                          to={PATH_APP.coupon.give}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Quản lý chương trình khuyến mại')}{' '}
                        </Link>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_POLICY}>
                        <Link
                          to={PATH_APP.policy.root}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Chính sách')}{' '}
                        </Link>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_SHIPPINGCOMPANY}>
                        <Link
                          to={PATH_APP.phippingVendor.root}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Nhà vận chuyển')}{' '}
                        </Link>
                      </WithPermission>
                      {/* navbar branch system */}
                      {/* <div className="dropdown">
                        <WithOrPermission
                          permission={[
                            POLICY.READ_BANNER,
                            POLICY.READ_WHPACKAGELEVEL
                          ]}
                        >
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={(e) => {
                              e.preventDefault();
                              setecommerce(!ecommerce);
                            }}
                          >
                            {props.t('System')}{' '}
                            <div className="arrow-down"></div>
                          </Link>
                        </WithOrPermission>
                        <div
                          className={classname('dropdown-menu', {
                            show: ecommerce
                          })}
                        > */}
                      <WithPermission permission={POLICY.READ_WHPACKAGELEVEL}>
                        <Link
                          to={PATH_APP.whPackageLevel.root}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Quản lý gói dịch vụ')}{' '}
                        </Link>
                      </WithPermission>
                      <WithPermission permission={POLICY.READ_BANNER}>
                        <Link
                          to={PATH_APP.banner.root}
                          className="dropdown-item"
                        >
                          {props.t('Banner')}
                        </Link>
                      </WithPermission>
                      {/* </div> // CLOSE SYSTEM (2 LINE)
                      </div> */}
                      <WithPermission permission={POLICY.READ_HIGHLIGHT}>
                        <Link
                          to={PATH_APP.highlight.root}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t('Highlight')}
                        </Link>
                      </WithPermission>

                      <WithPermission
                        permission={POLICY.READ_NOTIFICATIONBOTMANAGER}
                      >
                        <Link
                          to={PATH_APP.whNotificationBotManagement.root}
                          className="dropdown-item"
                        >
                          {props.t('Quản lý thông báo tự động')}
                        </Link>
                      </WithPermission>

                      <WithPermission permission={POLICY.READ_KEYWORDSEARCH}>
                        <Link
                          to={PATH_APP.keywordSearch.root}
                          className="dropdown-item"
                        >
                          {props.t('Quản lý từ khoá tìm kiếm')}
                        </Link>
                      </WithPermission>
                    </div>
                  </li>
                </WithOrPermission>
                <WithPermission permission={POLICY.READ_REFERRAL}>
                  <li className="nav-item dropdown ">
                    <Link
                      to=""
                      onClick={(e) => e.preventDefault()}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i class="fa fa-american-sign-language-interpreting me-2"></i>
                      {props.t('Giới thiệu')} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname('dropdown-menu registerOutView')}>
                      <Link
                        to={PATH_APP.referral.ConfigCampaign}
                        className="dropdown-item "
                      >
                        {props.t('Cấu hình chiến dịch')}
                      </Link>
                      <Link
                        to={PATH_APP.referral.RewardItem}
                        className="dropdown-item "
                      >
                        {props.t('Vật phẩm phần thưởng')}
                      </Link>
                      <Link
                        to={PATH_APP.referral.PointSystem}
                        className="dropdown-item "
                      >
                        {props.t('Điểm thưởng hệ thống')}
                      </Link>
                      <Link
                        to={PATH_APP.referral.Ranking}
                        className="dropdown-item "
                      >
                        {props.t('Phân loại khách hàng')}
                      </Link>
                      <Link
                        to={PATH_APP.referral.Activity}
                        className="dropdown-item "
                      >
                        {props.t('Hoạt động của chiến dịch')}
                      </Link>
                      <Link
                        to={PATH_APP.referral.ExchangePoint}
                        className="dropdown-item"
                      >
                        {props.t('Hệ số quy đổi điểm')}
                      </Link>
                    </div>
                  </li>
                </WithPermission>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.layout;
  const { policy } = state.user;
  return { leftMenu, policy };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
