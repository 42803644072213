import { BarChartOutlined, BellOutlined, ContactsOutlined, DollarOutlined, ExperimentOutlined, GlobalOutlined, HistoryOutlined, ProfileOutlined, ReadOutlined, ReconciliationOutlined, SettingOutlined, ShopOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import POLICIES, { CORE_ACTION, GROUP_POLICY } from "~/constants/policy";
import { PATH_APP } from "~/routes/paths";
 const buttonsDashboard = [
    {
      title: 'Tổng quan',
      icon: <BarChartOutlined />,
      path: PATH_APP.main.dashboard,
      permissions : [POLICIES.READ_WHCHARTDASHBOARDHEALTHCARE]
    },
    {
      title: 'Đơn hàng',
      icon: <ProfileOutlined />,
      permissions : GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WHBILL,
      children: [
        {
          title: 'Tất cả đơn hàng',
          path: PATH_APP.whBill.root,
          permissions : [POLICIES.READ_WHBILL],
        },
        {
          title: 'Đơn hàng tạm',
          path: PATH_APP.whBill.orderTemp,
          permissions : [POLICIES.READ_QUOTATIONS]
        },
        {
          title: 'Lịch hẹn đang xử lý',
          path: PATH_APP.whBill.processing,
          permissions : [POLICIES.READ_WHBILL]
        },
        {
          title: 'Yêu cầu huỷ cuộc hẹn',
          path: PATH_APP.partnerCancelAppointment.root,
          permissions : [POLICIES.READ_WHAPPOINTMENTCANCELPARTNER]
        },
        {
          title: 'Khấu hao đơn hàng',
          path: "/depreciation",
          permissions : [POLICIES.READ_WHBILL],
        },
      ]
    },
    
    {
      title: 'Đối tác',
      icon:<i class="uil uil-user-md"></i>,
      permissions : GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WHPARTNER,
      children: [
        {
          title: 'Danh sách đối tác',
          path: PATH_APP.whPartner.root,
          permissions : [POLICIES.READ_WHPARTNER],
        },
        {
          title: 'Thêm đối tác',
          path: PATH_APP.whPartnerImport.root,
          permissions : [POLICIES.WRITE_WHPARTNER,POLICIES.READ_POTENTIALPARTNER]
        },
        {
          title: 'Referral',
          path: PATH_APP.whPartner.referral,
          disabled:true,
          permissions : [POLICIES.READ_WHPARTNER]
        },
        {
          title: 'Công cụ dụng cụ',
          path: PATH_APP.whPartner.equipment,
          disabled:true,
          permissions : [POLICIES.READ_WHPARTNER]
        },
      ]
    },
    {
      title: 'Khách hàng',
      icon: <UserOutlined />,
      permissions : GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_CUSTOMER,
      children: [
        {
          title: 'Danh sách khách hàng',
          path: PATH_APP.whCustomerManagement.root,
          permissions : [POLICIES.READ_CUSTOMER],
        },
        {
          title: 'Thêm khách hàng',
          path: PATH_APP.whCustomerImport.root,
          permissions : [
            POLICIES.WRITE_CUSTOMER,
            POLICIES.READ_POTENTIALCUSTOMER,
          ]
        },
        {
          title: 'Referral',
          path: PATH_APP.whCustomerManagement.referral,
          disabled:true,
          permissions : [POLICIES.READ_CUSTOMER]
        },
        {
          title: 'Liên hệ',
          path: 'wh-contact',
          permissions : [POLICIES.READ_WHCONTACT]
        },
      ]
    },
    {
      title: 'Kho',
      icon:<ExperimentOutlined />,
      permissions : GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WAREHOUSE,
      children: [
        {
          title: 'Quản lý kho',
          path: '/list-warehouse',
          permissions : [POLICIES.READ_WAREHOUSE],
        },
        {
          title: 'Danh mục thiết bị y tế',
          path: PATH_APP.medicalEquipments.root,
          permissions : [POLICIES.READ_WAREHOUSE],
        },
        {
          title: 'Danh mục nhóm sản phẩm',
          path: PATH_APP.productCatalogue.root,
          permissions : [POLICIES.READ_WAREHOUSE],
        },
        {
          title: 'Danh sách sản phẩm',
          path: PATH_APP.productList.root,
          permissions : [POLICIES.READ_WAREHOUSE],
        },
        {
          title: 'Quản lý phiếu bàn giao',
          path: PATH_APP.voucherWareHouse.root,
          permissions : [POLICIES.READ_WAREHOUSE],
        },
        {
          title: 'Quản lý phiếu xác nhận',
          path: "/confirm-voucher-warehouse",
          permissions : [POLICIES.READ_WAREHOUSE],
        },
        {
          title: 'Quản lý phiếu luân chuyển',
          path: "/delivery-coupon",
          permissions : [POLICIES.READ_PRODUCTDELIVERY, POLICIES.READ_PRODUCTDELIVERY],
        },
      ]
    },
    {
      title: 'Quỹ',
      icon: <DollarOutlined />,
      permissions : GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WHVOUCHER,
      children: [
        {
          title: 'Sổ quỹ',
          path: PATH_APP.whVoucher.root,
          permissions : [POLICIES.READ_WHVOUCHERS],
        },
        {
          title: 'Doanh thu hệ thống',
          path: PATH_APP.whReport.system,
          permissions : [POLICIES.READ_WHREVENUESYSTEM]
        },
        {
          title: 'Doanh thu đối tác',
          path: PATH_APP.whReport.partner,
          permissions : [POLICIES.READ_WHREVENUEPARTNER]
        },
        {
          title: 'Doanh thu khách hàng',
          path: PATH_APP.whReport.customer,
          permissions : [POLICIES.READ_WHREVENUECUSTOMER]
        },
        {
          title: 'Yêu cầu rút tiền',
          path: PATH_APP.referral.RequestVoucher,
          permissions : [POLICIES.READ_WHREQUESTVOUCHER]
        },
        {
          title: 'Giao dịch',
          path: PATH_APP.whTransaction.root,
          permissions : [POLICIES.READ_WHTRANSACTION]
        },
      ]
    },
    {
      title: 'Cài đặt',
      icon: <SettingOutlined />,
      permissions : GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WHSETTING,
    children: [
        {
          title: 'Nhóm dịch vụ',
          path: PATH_APP.whCategory.root,
          permissions : [POLICIES.READ_WHSERVICEGROUP],
        },
        {
          title: 'Dịch vụ',
          path: PATH_APP.whService.root,
          permissions : [POLICIES.READ_WHSERVICE],
        },
        {
          title: 'Quy định thời gian',
          path: PATH_APP.whSessionOfDay.root,
          permissions : [POLICIES.READ_WHTIMEREGULATION],
        },
        {
          title: 'Bằng cấp',
          path: PATH_APP.rateConfig.degreeList,
          permissions : [POLICIES.READ_WHCREDENTIAL],
        },
        {
          title: 'Chức vụ',
          path: PATH_APP.rateConfig.position,
          permissions : [POLICIES.READ_WHPOSITION],
        },
        {
          title: 'Kinh nghiệm',
          path: PATH_APP.rateConfig.experience,
          permissions : [POLICIES.READ_WHEXPERIENCE],
        },
        {
          title: 'Cán bộ chuyên môn',
          path: PATH_APP.tableRanking.root,
          permissions : [POLICIES.READ_WHPROFESSIONALSTAFF],
        },
        {
          title: 'Định mức công việc',
          path: PATH_APP.whWorkNorm.root,
          permissions : [POLICIES.READ_WHJOBSTANDARD],
        },
        {
          title: 'Khách hàng đánh giá',
          path: PATH_APP.evaluate.root,
          permissions : [POLICIES.READ_WHCUSTOMEREVALUATION],
        },
        {
          title: 'Set up Hướng dẫn đặt dịch vụ',
          path: PATH_APP.serviceBookingInstruction.root,
          permissions : [POLICIES.READ_WHSERVICEBOOKINGINSTRUCTIONSSETUP],
        },
        {
          title: 'Quản lý Khoá đào tạo',
          path: PATH_APP.whTrainingCourse.root,
          permissions : [POLICIES.READ_WHCOURSETRAINING],
        },
        {
          title: 'Thiết bị y tế',
          path: PATH_APP.whMedicines.root,
          permissions : [POLICIES.READ_WHMEDICALEQUIPMENT],
        },
        {
          title: 'Nơi thực hiện dịch vụ',
          path: PATH_APP.servicePlace.root,
          permissions : [POLICIES.READ_WHHEALTHCAREFACILITY],
        },
        {
          title: 'Danh sách bệnh viện',
          path: PATH_APP.hospitalList.root,
          permissions : [POLICIES.READ_WHHEALTHCAREFACILITY],
        },
      ]
    },
    {
      title: 'Giới thiệu',
      icon:<i className="fa fa-american-sign-language-interpreting me-2"></i>,
      permissions : [POLICIES.READ_REFERRAL],
      children: [
        {
          title: 'Cấu hình chiến dịch',
          path: PATH_APP.referral.ConfigCampaign,
          permissions : [POLICIES.READ_REFERRAL],
        },
        {
          title: 'Vật phẩm phần thưởng',
          path: PATH_APP.referral.RewardItem,
          permissions : [POLICIES.READ_REFERRAL]
        },
        {
          title: 'Điểm thưởng hệ thống',
          path: PATH_APP.referral.PointSystem,
          permissions : [POLICIES.READ_REFERRAL]
        },
        {
          title: 'Phân loại khách hàng',
          path: PATH_APP.referral.Ranking,
          permissions : [POLICIES.READ_REFERRAL]
        },
        {
          title: 'Hoạt động của chiến dịch',
          path: PATH_APP.referral.Activity,
          permissions : [POLICIES.READ_REFERRAL]
        },
        {
          title: 'Hệ số quy đổi điểm',
          path: PATH_APP.referral.ExchangePoint,
          permissions : [POLICIES.READ_REFERRAL]
        },
        {
          title: 'Hướng dẫn sử dụng Mã giới thiệu (App)',
          path: PATH_APP.referral.IntroUsingRefCode,
          permissions : [POLICIES.READ_REFERRAL]
        },
      ]
    },
    {
      title: 'Lịch sử',
      icon: <HistoryOutlined />,
      path: '/history',
      permissions : [POLICIES.READ_HISTORY]
    },
  ];
 const buttonsClinic = [
    {
      title: 'Tổng quan',
      icon: <BarChartOutlined />,
      path: PATH_APP.clinicDashboard.root,
      permissions : [POLICIES.READ_CHARTCLINIC]
    },
    {
      title: 'Danh sách lịch hẹn',
      icon: <ContactsOutlined />,
      path: PATH_APP.appointment.root,
      permissions : [POLICIES.READ_APPOINTMENT]
    },
    {
      title: 'Lịch hẹn liên kết',
      icon: <ReconciliationOutlined />,
      path: PATH_APP.associateApointment.root,
      permissions : [POLICIES.READ_AFFILIATEDAPPOINTMENT]
    },
    {
      title: 'Danh sách phòng khám',
      icon: <i class="uil uil-panel-add"></i>,
      path: PATH_APP.clinics.root,
      permissions : [POLICIES.READ_PARTNERCLINIC]
    },
  ];
 const buttonsTenant = [
    {
      title: 'Danh sách đối tác phần mềm',
      icon: <i class="uil uil-panel-add"></i>,
      path: PATH_APP.clinics.root,
      permissions : [POLICIES.READ_PARTNERCLINIC]
    },
  ];
 const buttonsPharmacyOnline = [
    {
      title: 'Tổng quan',
      icon: <BarChartOutlined />,
      path: PATH_APP.pharmacyOnlineDashboard.root,
      permissions : [POLICIES.READ_ORDER]
    },
    {
      title: 'Tất cả đơn hàng',
      icon: <ProfileOutlined />,
      path: PATH_APP.order.allOrder,
      permissions : [POLICIES.READ_ORDER]
    },
    {
      title: 'Đơn hàng đang xử lý',
      icon: <ReconciliationOutlined />,
      path: PATH_APP.order.pendingOrder,
      permissions : [POLICIES.READ_ORDER]
    },
    {
      title: 'Đối tác',
      icon:<i class="uil uil-user-md"></i>,
      path: PATH_APP.pharmacies.root,
      permissions : [POLICIES.READ_PHARMACY]
    },
  ];
  const buttonsBranch = [
    {
      title: 'Chi nhánh',
      icon : <i className="uil uil-hospital "></i>,
      path: PATH_APP.branch.root,
      permissions : [POLICIES.READ_BRANCH]
    },
    {
      title: 'Đăng kí hoạt động dịch vụ',
      icon : <i className="uil uil-file-plus-alt"></i>,
      path: "/wh-service-branch",
      permissions : [POLICIES.READ_SERVICEBRANCH]
    },
  ];
  const buttonsDashCMS = [
    {
      title: 'Danh sách các bác sĩ liên kết với WorldCareVN',
      icon: <i class="uil uil-clinic-medical"></i>,
      permissions : [POLICIES.READ_MEDICALCLINIC],
      path: PATH_APP.staff.root,
      // children : [
      //   {
      //     title: 'Danh sách nhân viên',
      //     path: PATH_APP.staff.root,
      //     permissions : [POLICIES.READ_MEDICALCLINIC]
      //   },
      // ]
    },
    {
      title: 'Tin tức',
      icon: <i class="uil uil-newspaper"></i>,
      permissions : [POLICIES.READ_ANNOUNCEMENT],
      children : [
        {
          title: 'Danh mục tin tức',
          path: PATH_APP.newsCategory.root,
          permissions : [POLICIES.READ_ANNOUNCEMENT]
        },
        {
          title: 'Danh sách tin tức',
          path: PATH_APP.news.root,
          permissions : [POLICIES.READ_ANNOUNCEMENT]
        },
      ]
    },
    
    {
      title: 'Thiết lập',
      icon: <SettingOutlined />,
      permissions : [POLICIES.READ_CONFIGURATION],
      children : [
        {
          title: 'Bằng cấp',
          path: PATH_APP.config.degree,
          permissions : [POLICIES.READ_CONFIGURATION]
        },
        {
          title: 'Chuyên môn',
          path: PATH_APP.config.speciality,
          permissions : [POLICIES.READ_CONFIGURATION]
        },
        {
          title: 'Nhóm nhân viên',
          path: PATH_APP.config.staffGroup,
          permissions : [POLICIES.READ_CONFIGURATION]
        },
      ]
    },
    {
      title: 'Đối tác hàng đầu',
      icon: <UserOutlined />,
      path: PATH_APP.partnership.root,
      permissions : [POLICIES.READ_LEADINGPARTNER],
    },
    {
      title: 'Mã giảm giá',
      icon:<i class="uil uil-ticket"></i>,
      path: PATH_APP.coupon.root,
      permissions : [POLICIES.READ_DISCOUNTCODE],
    },
    {
      title: 'Quản lý chương trình khuyến mại',
      icon: <i class="uil uil-gift"></i>,
      path: PATH_APP.coupon.give,
      permissions : [POLICIES.READ_GIVECOUPON],
    },
    {
      title: 'Chính sách',
      icon: <i class="uil uil-file-shield-alt"></i>,
      path: PATH_APP.policy.root,
      permissions : [POLICIES.READ_POLICY],
    },
    {
      title: 'Nhà vận chuyển',
      icon:<i class="uil uil-truck"></i>,
      path: PATH_APP.phippingVendor.root,
      permissions : [POLICIES.READ_SHIPPINGCOMPANY],
    },
    {
      title: 'Quản lý gói dịch vụ',
      icon:<i class="uil uil-backpack"></i>,
      path: PATH_APP.whPackageLevel.root,
      permissions : [POLICIES.READ_WHPACKAGELEVEL],
    },
    
    {
      title: 'Giao diện trang chính',
      icon: <SettingOutlined />,
      permissions : [POLICIES.READ_BANNER],
      children : [
      
        {
          title: 'Lợi ích khi sử dụng phần mềm',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.uiClient.softwareBenefit,
        },
        {
          title: 'Đối tượng sử dụng phần mềm',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.uiClient.softwareTarget,
        },
        {
          title: 'Banner',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.banner.root,
        },
    
        {
          title: 'Ấn phẩm WorldPharma',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.gallery.root,
        },
        {
          title: 'Ấn phẩm WorldCareVn',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.addYoutube.root,
        },
        {
          title: 'Truyền thông báo chí',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.sayAboutUs.root,
        },
        {
          title: 'Thông tin Tải ứng dụng',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.appDownload.root,
        },
        {
          title: 'Cuối trang (Footer)',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.uiClient.footer,
        },
        {
          title: 'Quảng cáo',
          icon:<i class="uil uil-image"></i>,
          path: PATH_APP.uiClient.adv,
        },
      ]
    },
    {
      title: 'Tuyển dụng',
      icon: <SettingOutlined />,
      permissions : [POLICIES.READ_JOB],
      children : [
        {
          title: 'Bài viết tuyển dụng',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.job.root,
        },
    
        {
          title: 'Vị trí ứng tuyển',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.positionJob.root,
        },

        {
          title: 'Bộ câu hỏi ứng tuyển',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.jobQuestion.root,
        },
    
        {
          title: 'Danh sách ứng tuyển',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.applyJob.root,
        },
        {
          title: 'Câu trả lời của ứng viên',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.jobForm.root,
        },
        {
          title: 'Hướng dẫn sử dụng',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.tutorialJob.root,
        },
        {
          title: 'Thiết lập Hướng dẫn sử dụng',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.settingTutorialJob.root,
        },
        {
          title: 'Banner Tuyển dụng',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.uiClient.jobBanner,
        },
        {
          title: 'Công ty',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.companyJob.root,
        },
        {
          title: 'Thông tin Công ty',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.infoCompany.root,
        },
        {
          title: 'Giá trị cốt lõi',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_JOB],
          path: PATH_APP.coreValue.root,
        },
      ]
    },
    {
      title: 'Đăng ký đối tác',
      icon: <SettingOutlined />,
      permissions : [POLICIES.READ_CONFIGWHPARTNERREGISTER],
      children : [
        {
          title: 'Thiết lập',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_CONFIGWHPARTNERREGISTER],
          path: PATH_APP.registerWhPartner.config,
        },
        {
          title: 'Loại Form',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_CONFIGWHPARTNERREGISTER],
          path: PATH_APP.registerWhPartner.typeForm,
        },
        {
          title: 'Danh sách đăng ký',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_CONFIGWHPARTNERREGISTER],
          path: PATH_APP.registerWhPartner.list,
        },
    
      ]
    },
    {
      title: 'Phần mềm',
      icon: <SettingOutlined />,
      permissions : GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_GROUP_SOFTWARE,
      children : [
        {
          title: 'Danh sách phần mềm',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_SOFTWARE],
          path: PATH_APP.software.listSoftWare,
        },
        {
          title: 'Danh sách dùng thử',
          icon:<i class="uil uil-image"></i>,
          permissions : [POLICIES.READ_SOFTWARETRIAL],
          path: PATH_APP.software.listTrial,
        },

    
      ]
    },
  

    {
      title: 'Highlight',
      icon:<i class="uil uil-flower"></i>,
      path: PATH_APP.highlight.root,
      permissions : [POLICIES.READ_HIGHLIGHT],
    },
    {
      title: 'Quản lý thông báo tự động',
      icon:<BellOutlined />,
      path: PATH_APP.whNotificationBotManagement.root,
      permissions : [POLICIES.READ_NOTIFICATIONBOTMANAGER],
    },
    {
      title: 'Quản lý từ khoá tìm kiếm',
      icon:<i class="uil uil-search"></i>,
      path: PATH_APP.keywordSearch.root,
      permissions : [POLICIES.READ_KEYWORDSEARCH],
    },
    
  ];

  const buttonSystem = [
    {
      title: 'Quản lý phiên bản (App)',
      path: PATH_APP.system.root,
      icon : <GlobalOutlined />,
      permissions : [POLICIES.READ_SYSTEM],
    },
  ];
  const buttonsTodo=[
    {
      title: 'Quản lý công việc',
      path: PATH_APP.workFlow.root,
      icon : <UserOutlined />,
      permissions : [],
    },
    {
      title:'Cấu hình trạng thái công việc',
      path: PATH_APP.statusConfig.root,
      icon : <UserOutlined />,
      permissions : [POLICIES.READ_TODOCONFIGSTATUS],
    }
  ]
  export default {
    buttonsDashboard,
    buttonsClinic,
    buttonsPharmacyOnline,
    buttonsBranch,
    buttonsDashCMS,
    buttonSystem,
    buttonsTodo,
    buttonsTenant,
  }
