import user from './user';
import hospital from './hospital';
import appointment from './appointment';
import degree from './degree';
import speciality from './speciality';
import geo from './geo';
import staff from './staff';
import service from './service';
import newsCategory from './newsCategory';
import news from './news';
import staffGroup from './staffGroup';
import branch from './branch';
import employee from './employee';
import order from './order';
import employeeGroup from './employeeGroup';
import partner from './partner';
import userEmployee from './userEmployee';
import partnership from './partnership';
import coupon from './coupon';
import policy from './policy';
import dashboard from './dashboard';
import shippingVendor from './shippingVendor';
import whBill from './whBill';
import whCategory from './whCategory';
import whPackageLevel from './whPackageLevel';
import whService from './whService';
import whSessionOfDay from './whSessionOfDay';
import whPartner from './whPartner';
import whReceiptVoucher from './whReceiptVoucher';
import whPaymentVoucher from './whPaymentVoucher';
import whBillItem from './whBillItem';
import whAppointment from './whAppointment';
import rateConfig from './rateConfig';
import whCustomerManagement from './whCustomerManagement';
import whCustomerImport from './whCustomerImport';
import evaluate from './evaluate';
import systemConfiguration from './systemConfiguration';
import customerType from './customerType';
import notificationBotManagement from './notificationBotManagement';
import importExcel from './importExcel';
import quotations from './quotations';
import banner from './banner';
import highlight from './highlight';
import whCategoryShort from './whCategoryShort';
import whPartnerImport from './whPartnerImport';
import TrainingCourse from './TrainingCourse';
import TrainingCoursePartner from './trainingCoursePartner';
import TimeSheet from './timeSheet';
import configCampaign from './configCampaign';
import rewardItem from './rewardItem';
import pointSystem from './pointSystem';
import whWorkNorm from './whWorkNorm';
import LogCourseTraining from './logCourseTraining';
import reportPartner from './reportPartner';
import reportSystem from './reportSystem';
import TipsPartner from './tipsPartner';
import Transaction from './transaction';
import whReportCustomer from './whReportCustomer';
import whMedicines from './whMedicines';
import whReportMedicines from './whReportMedicines';
import appointmentAssociate from './appointmentAssociate';
import giveCoupon from './giveCoupon';
import exportExcel from './exportExcel';
import ranking from './ranking';
import keywordSearch from './keywordSearch';
import memberRanking from './memberRanking';
import exchangePoint from './exchangePoint';
import activityReferral from './activityReferral';
import hospitalList from './hospitalList';
import servicePlace from './servicePlace';
import whAllVoucher from './whAllVouchers';
import partnerCancelAppointment from './partnerCancelAppointment';
import clinicDashboard from './clinicDashboard';
import pharmacyOnlineDashboard from './pharmacyOnlineDashboard';
import requestVoucher from './requestVoucher';
import wallet from './wallet';
import processCampaign from './processCampaign';
import percentChangeCustomerToPartner from './percentChangeCustomerToPartner';
import medicalEquipment from './medicalEquipment';
import productCatalogue from './productCatalogue';
import productList from './productList';
import apiKeyWareHouse from './apiKeyWareHouse';
import wareHouseVoucher from './wareHouseVoucher';
import wareHouse from './wareHouse';
import bonusIntroCustomer from './bonusIntroCustomer';
import preBooking from './preBooking';

import whContact from './whContact';
import history from './history';
import confirmVoucherWarehouse from './confirmVoucherWarehouse';
import unConfirmWhBill from './unConfirmWhBill';
import listWareHouse from './listWareHouse';
import deliveryVoucher from './deliveryVoucher';
import workFlow from './workFlow';
import workSprint from './workSprint';
import MyNotification from './myNotification';
import Location from './location';
import statusConfig from './statusConfig';

import system from './system';
import introUsingRefCode from './introUsingRefCode';
import groupCategory from './groupCategory';
import UI from './UI';
import job from './job';
import positionJob from './positionJob';
import applyJob from './applyJob';
import SettingTutorialJob from './SettingTutorialJob';
import configWhPartnerRegisterTypeForm from './configWhPartnerRegisterTypeForm';
import whPartnerRegister from './whPartnerRegister';
import companyJob from './companyJob';
import infoCompany from './infoCompany';
import coreValue from './coreValue';
import listSoftware from './listSoftware';
import listTrial from './listTrial';
import uiClient from './uiClient';
import jobQuestion from './jobQuestion';
import jobForm from './jobForm';
import adv from './adv';

const api = {
  highlight,
  banner,
  user,
  hospital,
  appointment,
  degree,
  speciality,
  geo,
  staff,
  service,
  newsCategory,
  news,
  staffGroup,
  branch,
  employee,
  employeeGroup,
  partner,
  order,
  userEmployee,
  partnership,
  coupon,
  policy,
  dashboard,
  shippingVendor,
  whBill,
  whCategory,
  whPackageLevel,
  whService,
  whSessionOfDay,
  whPartner,
  whReceiptVoucher,
  whPaymentVoucher,
  whBillItem,
  whAppointment,
  rateConfig,
  whCustomerManagement,
  evaluate,
  systemConfiguration,
  customerType,
  notificationBotManagement,
  whCustomerImport,
  importExcel,
  quotations,
  whCategoryShort,
  whPartnerImport,
  TrainingCourse,
  TrainingCoursePartner,
  TimeSheet,
  configCampaign,
  rewardItem,
  pointSystem,
  whWorkNorm,
  LogCourseTraining,
  reportPartner,
  TipsPartner,
  reportSystem,
  Transaction,
  whReportCustomer,
  whMedicines,
  whReportMedicines,
  appointmentAssociate,
  giveCoupon,
  exportExcel,
  ranking,
  keywordSearch,
  memberRanking,
  exchangePoint,
  activityReferral,
  appointmentAssociate,
  hospitalList,
  servicePlace,
  clinicDashboard,
  pharmacyOnlineDashboard,
  medicalEquipment,
  productCatalogue,
  productList,
  apiKeyWareHouse,
  wareHouseVoucher,
  wareHouse,
  bonusIntroCustomer,
  preBooking,
  whContact,
  partnerCancelAppointment,
  whAllVoucher,
  keywordSearch,
  memberRanking,
  exchangePoint,
  activityReferral,
  requestVoucher,
  wallet,
  processCampaign,
  percentChangeCustomerToPartner,
  history,
  confirmVoucherWarehouse,
  unConfirmWhBill,
  listWareHouse,
  deliveryVoucher,
  workFlow,
  MyNotification,
  Location,
  
  introUsingRefCode,
  system,
  workSprint,
  statusConfig,
  groupCategory,
  UI,
  job,
  positionJob,
  applyJob,
  SettingTutorialJob,
  configWhPartnerRegisterTypeForm,
  whPartnerRegister,
  companyJob,
  infoCompany,
  coreValue,
  listSoftware,
  listTrial,
  uiClient,
  jobQuestion,
  jobForm,
  adv,
};

export default api;
