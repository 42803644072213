import { MINUTES_PER_HOUR, TIME_UNIT } from "~/constants/defaultValue";
import { notification, Skeleton } from "antd";
import moment from "moment";
import { forIn, get, groupBy, keys ,compact, flattenDeep, uniq,} from "lodash";
import { useLayoutEffect, useState } from "react";
import { unSubscribeToken } from "~/hooks";
import { removeAxiosToken } from "~/api/requester";

export const getExistProp = (data) => {
  const result = Object.keys(data).reduce((nextData, key) => {
    if (data[key]) {
      return {
        ...nextData,
        [key]: data[key]
      };
    }

    return nextData;
  }, {});

  return result;
};

export const onFormAddressChange = ({ address }, form) => {
  const shouldResetDistrictAndWards = address && address.cityId;
  if (shouldResetDistrictAndWards) {
    form.setFieldsValue({
      address: {
        districtId: null,
        wardId: null
      }
    });
  }

  const shouldResetWards = address && address.districtId;
  if (shouldResetWards) {
    form.setFieldsValue({
      address: {
        wardId: null
      }
    });
  }
};

export function removeAccents(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
}

export const filterAcrossAccents = (input, option) => {
  return (
    removeAccents(option.children.toLowerCase()).indexOf(input.toLowerCase()) >=
    0
  );
};

export const searchByName = (data, searchText) => {
  if (!data || !searchText) {
    return data;
  }

  return data.filter(
    ({ name }) =>
      removeAccents(name).toLowerCase().indexOf(searchText.toLowerCase()) >= 0
  );
};

export const formatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const floorFormatter = value => `${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const ceilFormatter = value => `${Math.ceil(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const humanize = (timeUnit, duration, stillDisplayZero,durationAsMinute) => {
  if (!duration) return stillDisplayZero ? `0 ${timeUnit}` : "";
  let result;
  if (timeUnit === TIME_UNIT.MINUTE && duration >= MINUTES_PER_HOUR) {
    const hour = Math.floor(duration / MINUTES_PER_HOUR);
    const min = duration % MINUTES_PER_HOUR;
    result = `${hour} ${TIME_UNIT.HOUR} ${min > 0 ? `${min} ${TIME_UNIT.MINUTE}` : ""}`;
  } else {
    if(durationAsMinute){
      const duration_ = timeUnit === TIME_UNIT.HOUR ? moment.duration(duration,"minute").asHours() : moment.duration(duration,"minute").asMinutes()
      result = `${duration_} ${timeUnit}`;
    }else{

      result = `${duration} ${timeUnit}`;
    }
  }
  return result;
};

export const notifyHttpError = (error) => {
  console.error(error);
  notification.error({
    message: error?.response?.data?.httpCode,
    description: error?.response?.data?.message,
  });
}

export const setEndofDate = (date) => moment(date).endOf('day').format("YYYY-MM-DD HH:mm:ss")
export const setStartofDate = (date) => moment(date).startOf('day').format("YYYY-MM-DD HH:mm:ss")
export const adapterStartAndEndDate = (query) => ({...query,
  startDate : setStartofDate(get(query,'startDate')),
  endDate : setEndofDate(get(query,'endDate')),
})

export const getDateByTypePicker = (value,optiondate='day')=>{
  if(!value){
    return value
  }
  const [valueA,vallueB] = Array.isArray(value)? value:[value];
  const optiondate_ = optiondate!=='range' ?optiondate :'day'
  const m = moment(valueA).startOf(optiondate_).format('YYYY-MM-DD')
  const me = moment(vallueB??valueA).endOf(optiondate_).format('YYYY-MM-DD')
  return compact([m,me]).toString()
}

export const formatPhone = (phone) => phone ? phone?.replace(/^(\+84)/, "0") : '';// format +84 with 0


/**
 * 
 * @param {Array} listOptionSearch expects an array {label, value}
 * @param {Object} query query search parameters
 * @param {Object} param param Searching
 * To clear the query search except param
 * Example: after search want to remove all query in listOptionSearch
 */
export const clearQuerySearch = (listOptionSearch,query,param) => {
  // group listOptionSearch by value
  const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'))
  // loop query to remove all query in listOptionSearch  except param
    forIn(query,(values,key,obj) =>{
      if(groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))){
        obj[key] = null
      }
    })
  }
  
export const StringToSlug = (str) => {
  const result = removeAccents(str)
  return result.replaceAll(/\s+/g,'-')
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
export const renderLoading = (component,isLoading) => isLoading ? (
  <Skeleton.Input active />
) : (
  component
);
export const filterOptionSlug = (input,option) => StringToSlug(get(option,'label','')?.toLowerCase())?.includes(StringToSlug(input?.trim()?.toLowerCase()));

export const convertQueryString = (queryString) => {
  const queryJson = Object.entries(getExistProp(queryString));
  const stringQuery = queryJson.reduce((total, cur, i) => (
    total.concat((i === 0 ? cur[0] ? '?' : '' : '&'), cur[0], '=', encodeURIComponent(cur[1]))
  ), '');
  return stringQuery;
};



export const useExpandrowTableClick=()=>{
  const [select, setSelect] = useState([]);
  const onClick=(item)=> function () {
    const parentId = item.parentId;
    let children = item?.children ?? [];
    const id = item._id;
    if (children.length && id) {
      function repeat(value) {
        let res = [value._id];
        if (value?.children) {
          let child = value?.children.map(repeat);
          res = flattenDeep([...res, child]);
        }
        return res;
      }
      children = children.map(repeat);
      if (select.includes(id)) {
        let filter = select.filter((_id) => _id != id);
        filter = filter.filter(
          (_id) => !flattenDeep(children).includes(_id),
        );
        setSelect(filter);
      } else {
        setSelect(uniq(compact([...select, id, parentId])));
      }
    }
  }
  return {select,setSelect,onClick}
}

export function convertQueryToObject(){
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsObject = {};
  urlParams.forEach((value, key) => {
    paramsObject[key] = value;
  });
  return paramsObject
}

export const shouldUpdateAntDiff = (pre,next,field) => get(pre,field) !== get(next,field);

export const logout = () => {
      unSubscribeToken();
      removeAxiosToken();
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/login');
}
