import { Badge, Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Switch, Table } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseBorderBox, Breadcrumb, WithPermission } from '~/components/Common';
import TabBranch, { useContextBranch } from '~/components/Common/TabBranch';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { ROOT_COMPANYID } from '~/constants/defaultValue';
import POLICY from '~/constants/policy';
import { ACCEPT_TYPE, ACCEPT_TYPE_VI } from '~/constants/serviceBranch';
import {
  useAcceptWhServiceBranch,
  useDeleteWhServiceBranch,
  useMatchPolicy,
  useProfile,
  useResetWhService, useUpdateWhServiceBranch,
  useUpdateWhServiceBranchQueryParams, useWhServiceBranchPaging,
  useWhServiceBranchQueryParams, useWhServicesBranch
} from '~/hooks';
import { get, head, join, keys } from 'lodash';
import FormRegisterService from './FormRegisterService';

import { useBranchIdSessionStorage, useOptionsSelectAntd } from '~/hooks/utils';
import FormDeny from './FormDeny';
import { CheckCircleOutlined, CloseCircleTwoTone, DeleteOutlined } from '@ant-design/icons';
import api from '~/api';
import { filterOptionSlug } from '~/utils/helper';
import RegisterServiceSelect from './RegisterServiceSelect';
import DebounceSelectByCategory from '~/components/WhCategory/SearchByCategory';
const { Search } = Input;

const ColumnActions = ({
  id,
  status,
  updateWhService,
  profile,
  deleteWhService,
  branchId,
  ...restProps
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.DELETE_SERVICEBRANCH}>
          <Popconfirm
            title="Xoá dịch vụ"
            description="Bạn có chắc chắn xoá dịch vụ này?"
            onConfirm={()=> deleteWhService({
              id,
              branchId: branchId
            })}
            okText="Đồng ý"
            cancelText="Huỷ"
        >
            <DeleteOutlined
              style={{ color: 'red' }}
            />
          </Popconfirm>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_SERVICEBRANCH}>
        <p style={{ marginLeft: '10px' }} >|</p>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_SERVICEBRANCH}>
        <Switch
          checked={status === 'ACTIVE'}
          onChange={(value) =>
            updateWhService({ status: value ? 'ACTIVE' : 'INACTIVE', id })
          }
        />
      </WithPermission>
    </div>
  );
};

export default function WhServiceBranch() {
  const [form] = Form.useForm();
  const optionsTypeAccept = useOptionsSelectAntd(ACCEPT_TYPE_VI)
  const canDelete = useMatchPolicy(POLICY.DELETE_SERVICEBRANCH);
  const canAccept = useMatchPolicy(POLICY.UPDATE_ACCEPTSERVICE);
  const [query, onPagingChange] = useWhServiceBranchQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateWhServiceBranchQueryParams(query);
  const [whServices, isLoading] = useWhServicesBranch(query);
  const paging = useWhServiceBranchPaging();
  const [profile] = useProfile();
  const isRootCompanyId = useMemo(() => get(profile, 'branchId') === ROOT_COMPANYID,[profile])
  const [submitLoading, updateWhService] = useUpdateWhServiceBranch(() => {
    onCloseDeny();
    handleClose();
  });
  const [, acceptWhServiceBranch] = useAcceptWhServiceBranch(() => {
    onCloseDeny();
    handleClose();
  });
  const [, deleteWhService] = useDeleteWhServiceBranch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeny, setIsOpenDeny] = useState(false);
  const [payloadDeny, setPayloadDeny] = useState();
  const [service, setService] = useState(null)
  const [branchId] = useBranchIdSessionStorage()
  const [infoService, setInfoService] = useState(null);
  useResetWhService();
  const handleOpen = (record) => {
    setIsOpen(true);
    setService(record)
  };

  function handleClose() {
    setIsOpen(false);
    setService(null)
    setInfoService(null);
  };
  const onOpenDeny = (payload) => {
    setIsOpenDeny(true);
    setPayloadDeny(payload)
  };

  function onCloseDeny() {
    setIsOpenDeny(false);
    setPayloadDeny(null)
  };

  const handleAccept = (isAccess, id, note) => {
    let payload = {
      isAccess,
      id,
      note
    }
    acceptWhServiceBranch(payload);
  }
  const columns = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        const filterAreaAvailble = get(record, 'areaAvailble', [])?.map((item) => ({
          label: get(item, 'fullAddress', ''),
          value: get(item, 'path', '')
        }));
        return(
          <Button type='link' onClick={() => {
            handleOpen(record);
            setInfoService({
              service: {
                ...record?.service,
                whCategoryId: get(record, 'service.whCategoryId._id', ''),
              },
              branchId: get(record, 'branchId'),
              areaAvailble: filterAreaAvailble,
              serviceId: get(record, 'serviceId')
            });
          }}>
          {get(record, 'service.code', '')}
        </Button>
      )}
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => get(record, 'service.name.vi', '')
    },
    {
      title: 'Tên dịch vụ chi tiết',
      key: 'detailName',
      render: (value, record) => get(record, 'service.detailName.vi', '')
    },
    {
      title: 'Nhóm dịch vụ',
      key: 'whCategoryId',
      render: (value, record) => {
        return (
          record?.service?.whCategoryId?.name?.vi ??
          record?.service?.whCategoryId?.name
        );
      }
    },
    {
      title: 'Khu vực hoạt động',
      dataIndex: 'areaAvailble',
      key: 'areaAvailble',
      render: (value, record) => (record?.areaAvailble?.map((item) => (item?.fullAddress)))?.join(', ')
    },

    ...(canDelete
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '110px',
            render: (record) => {
              return (
                <ColumnActions
                  profile={profile}
                  {...record}
                  id={record?.serviceId}
                  updateWhService={updateWhService}
                  status={record?.status}
                  deleteWhService={deleteWhService}
                  branchId = {branchId}
                />
              );
            }
          }
        ]
      : [])
  ];

    columns.push({
      title: 'Phê duyệt',
      key: 'actionApprove',
      dataIndex: 'isAccess',
      width: '110px',
      align: 'center',
      render: (isAccess, record) => isAccess === ACCEPT_TYPE.NEW && isRootCompanyId
        ? <Row>
          <Col span={24}>
            <Popconfirm
              title="Xác nhận phê duyệt?"
              onConfirm={() => handleAccept(ACCEPT_TYPE.ACCEPT, record?.serviceId)}
              okText="Duyệt"
              cancelText="Huỷ"
              disabled={!canAccept}
            >
              <Button disabled={!canAccept} type='primary' className='rounded-3 d-flex align-items-center' icon={<CheckCircleOutlined />} block>Phê duyệt</Button>
            </Popconfirm>
          </Col>
          
          <Col span={24}>
          <Popconfirm
            title="Xác nhận từ chối?"
            onConfirm={() => onOpenDeny({ id: record?.serviceId, isAccess: ACCEPT_TYPE.DENY })}
            okText="Từ chối"
            cancelText="Huỷ"
            disabled={!canAccept}
          >
            <Button danger   disabled={!canAccept} className='rounded-3 d-flex align-items-center mt-1' block icon={<CloseCircleTwoTone twoToneColor={'red'} />} >Từ chối</Button>
          </Popconfirm>
          </Col>
      </Row>
        :
        <Popconfirm
          disabled={!get(record, 'note', '')}
          placement="topRight"
          overlayClassName='popConfirm-custom hide-action'
          title={
            <BaseBorderBox title={'Lý do từ chối'}>
              {get(record, 'note', '')}
            </BaseBorderBox>
          }
          icon={null}
        >
        {!isRootCompanyId && isAccess === ACCEPT_TYPE.NEW 
        ? <span className={`bill-status ${isAccess?.toLowerCase()}`}>
              Đang chờ duyệt...
            </span>
        :<Badge dot={get(record, 'note', '')}>
            <span className={`bill-status ${isAccess === ACCEPT_TYPE.DENY && 'cursor-pointer'} ${isAccess?.toLowerCase()}`}>
              {ACCEPT_TYPE_VI[isAccess]}
            </span>
          </Badge>
        }
          
        </Popconfirm>
    });

  const onValuesChange = (value) => {
    const { isAccess, whCategoryIds } = value;
    const keyChange = head(keys(value));
    switch (keyChange) {
      case 'isAccess':
        onParamChange({ isAccess: join(isAccess, ',') })
        break;
      case 'whCategoryIds':
        onParamChange({ whCategoryIds: join(whCategoryIds, ',') })
        break;
    
      default:
        break;
    }
  
  };

  return (
    <div className="page-wraper page-content wh-bills-page">
      <TabBranch>
        <div className="container-fluid">
          <Breadcrumb title="Đăng kí hoạt động dịch vụ" />

          <div className="page-content__main">
            <div className="page-content__left">
              <Form
                form={form}
                autoComplete="off"
                onFinish={() => { }}
                scrollToFirstError
                onValuesChange={onValuesChange}
                requiredMark={false}
                labelCol={{ sm: 24, md: 24, lg: 24 }}
              >
                <Form.Item label='Trạng thái phê duyệt' name='isAccess'>
                  <Select placeholder='Chọn trạng thái...' mode='multiple' allowClear options={optionsTypeAccept} />
                </Form.Item>
                <SelectMultiWhCategory form={form} query={query} onParamChange={onParamChange} />
              </Form>
            </div>



            <div className="page-content__right" >
              <div className="page-wraper__header">
                <Row gutter={32} justify="space-between">
                  <Col span={8}>
                    <Search
                      allowClear
                      style={{ width: 300 }}
                      placeholder="Tìm dịch vụ, mã dịch vụ"
                      enterButton
                      onSearch={(value) => onParamChange({ keyword: value?.trim() })}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </Col>
                  <Col span={8}>
                  </Col>
                  <WithPermission permission={POLICY.WRITE_SERVICEBRANCH}>
                    <Col span={8} align="end">
                      <Button type="primary" onClick={() => setIsOpen(true)}>
                        Đăng ký
                      </Button>
                    </Col>
                  </WithPermission>
                </Row>
              </div>
              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={5} />
              ) : (
                <Table
                  columns={columns}
                  dataSource={whServices || []}
                  size='middle'
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  onChange={(cur) => {
                    onParamChange({ page: cur?.current, limit: cur?.pageSize })
                  }}
                />
              )}
            </div>

            <ElementCustom
              isOpen={isOpen}
              handleClose={handleClose}
              updateWhService={updateWhService}
              infoService = {infoService}
            />

          </div>
        </div>
        <Modal
          visible={isOpenDeny}
          onCancel={onCloseDeny}
          footer={null}
          closable={null}
        >
          <FormDeny isLoading={submitLoading} onCancel={onCloseDeny} onSubmit={(values) => acceptWhServiceBranch({ ...payloadDeny, ...values })} />
        </Modal>
      </TabBranch>
    </div>
  );
}

const ElementCustom = ({ children, isOpen, handleClose, updateWhService, infoService }) => {
  const { branches } = useContextBranch();
  return (
    <>
      {children}
      <Modal
          destroyOnClose
          style={{ minWidth: '1200px'}}
          width={'auto'}
          title={`Đăng ký hoạt động cho dịch vụ`}
          visible={isOpen}
          footer={null}
          onCancel={() => handleClose()}
      >
        <RegisterServiceSelect
          onCancel={() => handleClose()}
          branches={branches}
          infoService={infoService}
          updateWhService={updateWhService}
        />
      </Modal>
    </>
  );
};

function SelectMultiWhCategory({form, query, onParamChange}){
  const [options,setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [whCategory, setWhCategory] = useState(null);
  // const fetch = useCallback(async() => {
  // try {
  //   form.resetFields();
  //   setLoading(true);
  //   const whCategory = await api.whCategory.getWhCategoryByAuthenByPermission();
  //   setOptions(whCategory?.map(item => ({
  //     label : get(item,'name',''),
  //     value : get(item,'key','')
  //   })));
  //   setLoading(false);
  // } catch (error) {
  //   setLoading(false);
  // }
  // },[form])
  // useEffect(() => fetch(), [fetch]);
  useEffect(() => {
    if (!whCategory && query?.whCategory) {
      // const splitWhCategory = query?.whCategory.split(',');
      setWhCategory(query?.whCategoryIds);
    };
  }, [query]);
  
  return <Form.Item label='Nhóm dịch vụ' name='whCategoryIds'>
  {/* <Select
  loading={loading}
  filterOption={filterOptionSlug}
   placeholder='Chọn nhóm dịch vụ...' mode='multiple' allowClear options={options} /> */}
      <DebounceSelectByCategory
        onChange={(e) => {
            setWhCategory(e);
            onParamChange({ whCategoryIds: e})
        }}
        value={whCategory}
        isFirstFetch={!!whCategory}
        placeholder="Tìm nhóm dịch vụ"
        style={{ minWidth: '200px' }}
      />
</Form.Item>
}
