import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useBranchIdSessionStorage
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhServices,
  createWhService,
  deleteWhService,
  getWhService,
  resetWhServiceState,
  updateWhService,
  copyWhService,
  getWhServicesBranch,
  updateWhServiceBranch,
  createWhServiceBranch,
  deleteWhServiceBranch,
  resetWhServiceBranchState,
  getWhServicesAvailable,
  acceptWhServiceBranch,
  getWhServicesDasboard
} from '~/redux/action';
import { useHistory,useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { forIn, get } from 'lodash';
import { WH_SERVICE_TYPESHOW } from '~/constants/defaultValue';
import { getExistProp } from '~/utils/helper';

const getSelector = key => state => state.whService[key];

const loadingSelector = getSelector('isLoading');
const whServicesSelector = getSelector('whServices');
const getWhServicesFailedSelector = getSelector('getWhServicesFailed');

//Get Service By Branch
const isLoadingServiceBranchSelector = getSelector('isLoadingServiceBranch');
const whServicesBranchSelector = getSelector('whServicesBranch');
const getWhServicesBranchFailedSelector = getSelector('getWhServicesBranchFailed');

const isGetWhServiceLoadingSelector = getSelector('isGetWhServiceLoading');
const whServiceSelector = getSelector('whService');
const getWhServiceFailedSelector = getSelector('getWhServiceFailed');

const isLoadingServiceDashboard = getSelector('isLoadingServiceDashboard');
const whServicesDashboardSelector = getSelector('whServicesDashboard');
const getWhServicesDashboardFailedSelector = getSelector('getWhServicesDashboardFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const deleteServiceBranchSuccessSelector = getSelector('deleteServiceBranchSuccess');
const deleteServiceBranchFailedSelector = getSelector('deleteServiceBranchFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const isSubmitServiceBranchLoadingSelector = getSelector('isSubmitServiceBranchLoading');
const createServiceBranchSuccessSelector = getSelector('createServiceBranchSuccess');
const createServiceBranchFailedSelector = getSelector('createServiceBranchFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const copySuccessSelector = getSelector('copySuccess');
const copyFailedSelector = getSelector('copyFailed');
const updateServiceBranchSuccessSelector = getSelector('updateServiceBranchSuccess');
const updateServiceBranchFailedSelector = getSelector('updateServiceBranchFailed');

const acceptServiceBranchSuccessSelector = getSelector('acceptServiceBranchSuccess');
const acceptServiceBranchFailedSelector = getSelector('acceptServiceBranchFailed');

const pagingSelector = getSelector('paging');
const pagingServiceBranchSelector = getSelector('pagingBranch');

export const useWhServicePaging = () => useSelector(pagingSelector);
export const useWhServiceBranchPaging = () => useSelector(pagingServiceBranchSelector);

export const useWhServiceQueryParams = () => {
  const [branchId] = useBranchIdSessionStorage();
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };
  const whCategory = query.get('whCategory') || null;

  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);
  const copyWhServiceSuccess = useSelector(copySuccessSelector);
  const createWhServiceBranchSuccess = useSelector(createServiceBranchSuccessSelector);
  const deleteWhServiceBranchSuccess = useSelector(deleteServiceBranchSuccessSelector);
  // const updateWhServiceBranchSuccess = useSelector(updateServiceBranchSuccessSelector);


  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
      whCategory
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhServiceSuccess,branchId,copyWhServiceSuccess, createWhServiceBranchSuccess, deleteWhServiceBranchSuccess, whCategory]);
};

export const useWhServiceBranchQueryParams = () => {
  const [branchId] = useBranchIdSessionStorage();
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const isAccess = query.get('isAccess');
  const whCategoryIds = query.get('whCategoryIds') || null;
  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;;

  const createWhServiceBranchSuccess = useSelector(createServiceBranchSuccessSelector);
  const deleteWhServiceBranchSuccess = useSelector(deleteServiceBranchSuccessSelector);
  // const updateWhServiceBranchSuccess = useSelector(updateServiceBranchSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
      isAccess,
      whCategoryIds,
    };

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword,branchId, createWhServiceBranchSuccess, deleteWhServiceBranchSuccess,isAccess,whCategoryIds,]);
};
export const useUpdateWhServiceBranchQueryParams = (query) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [keyword, setKeyword] = useState(get(query,'keyword'));
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: path,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword ,{setKeyword, onParamChange }];
};

export const useWhServices = query => {
  return useFetchByParam({
    action: getWhServices,
    loadingSelector,
    dataSelector: whServicesSelector,
    failedSelector: getWhServicesFailedSelector,
    param: query
  });
};
export const useWhServicesDashBoard = query => {
  return useFetchByParam({
    action: getWhServicesDasboard,
    loadingSelector,
    dataSelector: whServicesSelector,
    failedSelector: getWhServicesFailedSelector,
    param: query
  });
};

export const useWhServicesAvailable = query => {
  return useFetchByParam({
    action: getWhServicesAvailable,
    loadingSelector: loadingSelector,
    dataSelector: whServicesSelector,
    failedSelector: getWhServicesFailedSelector,
    param: query
  });
};

//Get Service by branch
export const useWhServicesBranch = query => {
  return useFetchByParam({
    action: getWhServicesBranch,
    loadingSelector: isLoadingServiceBranchSelector,
    dataSelector: whServicesBranchSelector,
    failedSelector: getWhServicesBranchFailedSelector,
    param: query
  });
};
//Create service for branch

export const useCreateWhServiceBranch = (callback) => {
  useSuccess(createServiceBranchSuccessSelector, 'Tạo mới dịch vụ cho chi nhánh thành công',callback);
  useFailed(createServiceBranchFailedSelector, 'Tạo mới dịch vụ cho chi nhánh thất bại');

  return useSubmit({
    loadingSelector: isSubmitServiceBranchLoadingSelector,
    action: createWhServiceBranch
  });
};
//

export const useCreateWhService = () => {
  const history = useHistory();
  const callback = () => history.push('/wh-service');

  useSuccess(createSuccessSelector, 'Tạo mới dịch vụ thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhService
  });
};

export const useUpdateWhService = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật dịch vụ thành công');
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhService
  });
};

export const useCopyWhService = () => {
  useSuccess(copySuccessSelector, 'Sao chép dịch vụ thành công');
  useFailed(copyFailedSelector, 'Sao chép dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: copyWhService
  });
};
//Update service for branch
export const useUpdateWhServiceBranch = (callback) => {
  useSuccess(updateServiceBranchSuccessSelector, 'Cập nhật dịch vụ cho chi nhánh thành công',callback);
  useFailed(updateServiceBranchFailedSelector, 'Cập nhật dịch vụ cho chi nhánh thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhServiceBranch
  });
};

//Accept service for branch
export const useAcceptWhServiceBranch = (callback) => {
  useSuccess(acceptServiceBranchSuccessSelector, 'Cập nhật dịch vụ cho chi nhánh thành công',callback);
  useFailed(acceptServiceBranchFailedSelector, 'Cập nhật dịch vụ cho chi nhánh thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: acceptWhServiceBranch
  });
};

export const useDeleteWhService = () => {
  useSuccess(deleteSuccessSelector, 'Xoá dịch vụ thành công');
  useFailed(deleteFailedSelector, 'Xoá dịch vụ thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhService
  });
};

//Delete service for branch

export const useDeleteWhServiceBranch = () => {
  useSuccess(deleteServiceBranchSuccessSelector, 'Xoá dịch vụ cho chi nhánh thành công');
  useFailed(deleteServiceBranchFailedSelector, 'Xoá dịch vụ cho chi nhánh thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhServiceBranch
  });
};

export const useWhService = id => {
  return useFetchByParam({
    action: getWhService,
    loadingSelector: isGetWhServiceLoadingSelector,
    dataSelector: whServiceSelector,
    failedSelector: getWhServiceFailedSelector,
    param: id
  });
};

export const useInitWhService = whService => {
  return useMemo(() => {
    if (!whService) {
      return {
        questions: [
          {
            text: {
              vi: '',
              en: '',
            },
            options: [
              {
                text: {
                  vi: '',
                  en: '',
                },
                image: '',
              }
            ]
          }
        ],
        packages: [
          {
            sessionPrices: [
              { 
                price: 0,
              }
            ]
          }
        ],
      };
    }


    const initValues = {
      ...whService,
      typeShow:get(whService,'typeShow',WH_SERVICE_TYPESHOW.MINUTES)
    };

    return initValues;
  }, [whService]);
};

export const useResetWhService = () => {
  useResetState(resetWhServiceState);
};

export const useResetWhServiceBranch = () => {
  useResetState(resetWhServiceBranchState);
};
