import { all } from 'redux-saga/effects';
import themeSaga from './user/tasks';
import hospitalSaga from './hospital/tasks';
import appointmentSaga from './appointment/tasks';
import degreeSaga from './degree/tasks';
import specialitySaga from './speciality/tasks';
import geoSaga from './geo/tasks';
import staffSaga from './staff/tasks';
import serviceSaga from './service/tasks';
import newsCategorySaga from './newsCategory/tasks';
import newsSaga from './news/tasks';
import staffGroupSaga from './staffGroup/tasks';
import layoutSaga from './layout/tasks';
import branchSaga from './branch/tasks';
import employeeSaga from './employee/tasks';
import employeeGroupSaga from './employeeGroup/tasks';
import partnerSaga from './partner/tasks';
import orderSaga from './order/tasks';
import userEmployeeSaga from './userEmployee/tasks';
import partnershipSaga from './partnership/tasks';
import couponSaga from './coupon/tasks';
import policySaga from './policy/tasks';
import dashboardSaga from './dashboard/tasks';
import shippingVendorSaga from './shippingVendor/tasks';
import whBillSaga from './whBill/tasks';
import Quotation from './quotations/tasks';
import whCategorySaga from './whCategory/tasks';
import whPackageLevelSaga from './whPackageLevel/tasks';
import whServiceSaga from './whService/tasks';
import whSessionOfDay from './whSessionOfDay/tasks';
import whPartner from './whPartner/tasks';
import whReceiptVoucher from './whReceiptVoucher/tasks';
import whPaymentVoucher from './whPaymentVoucher/tasks';
import whBillItem from './whBillItem/tasks';
import whAppointment from './whAppointment/tasks';
import rateConfig from './rateConfig/tasks';
import customerType from './customerType/tasks';
import whCustomerManagement from './whCustomerManagement/tasks';
import whCustomerImport from './whCustomerImport/tasks';
import importWhCustomerExcel from './importWhCustomerExcel/tasks';
import evaluate from './evaluate/tasks';
import systemConfiguration from './systemConfiguration/tasks';
import notificationBotManagement from './notificationBotManagement/tasks';
import whBanner from './banner/task';
import whCategoryShort from './whCategoryShort/tasks';
import whPartnerImport from './whPartnerImport/tasks';
import trainingCourse from './trainingCourse/tasks';
import trainingCoursePartner from './trainingCoursePartner/tasks';
import whWorkNorm from './whWorkNorm/tasks';
import timeSheet from './timeSheet/tasks';
import configCampaign from './configCampaign/tasks';
import rewardItem from './rewardItem/tasks';
import pointSystem from './pointSystem/tasks';
import tipsPartner from './tipsPartner/tasks';
import reportSystem from './reportSystem/tasks';
import whReportCustomer from './whReportCustomer/tasks';
import whMedicine from './whMedicine/tasks';
import whReportMedicines from './whReportMedicines/tasks';
import whHighlight from './whHighlight/tasks';
import transactions from './transaction/task';
import appointmentAssociate from './appointmentAssociate/task';
import giveCoupon from './giveCoupon/tasks';//
import ranking from './ranking/tasks';
import keywordSearch from './keywordSearch/tasks';//ss
import hospitalListSaga from './hospitalList/tasks';
import servicePlaceSaga from './servicePlace/tasks';
import bonusIntroCustomer from './bonusIntroCustomer/tasks';
import partnerCancelAppointment from './partnerCancelAppointment/tasks';
import whAllVouchers from './whAllVouchers/tasks';
import exchangePoint from './exchangePoint/tasks';
import activityReferral from './activityReferral/tasks';//s
import requestVoucher from './requestVoucher/tasks';//s
import wallet from './wallet/tasks';//s
import clinicDashboardSaga from './clinicDashboard/tasks';
import pharmacyOnlineDashboardSaga from './pharmacyOnlineDashboard/tasks';
import percentChangeCustomerToPartner from './percentChangeCustomerToPartner/tasks';//s
import medicalEquipment from './medicalEquipment/tasks';
import productCatalogue from './productCatalogue/tasks';
import productList from './productList/tasks';
import warehouseVoucher from './warehouseVoucher/tasks';
import whContact from './whContact/tasks';
import confirmVoucherWarehouse from './confirmVoucherWarehouse/tasks';//s
import unConfirmWhBill from './unConfirmWhBill/tasks';//s
import history from './history/tasks';//s

import watchWareHouseList from './listWareHouse/task';
import myNotification from './myNotification/tasks';//s

import deliveryVoucher from './deliveryVoucher/tasks';
import introUsingRefCode from './introUsingRefCode/tasks';//s
import system from './system/tasks';//s

import workList from './workList/tasks';
import workListTask from './workTask/tasks';
import workSprintTask from './workSprint/tasks';
import statusConfig from './statusConfig/tasks';
import groupCategory from './groupCategory/tasks';
import UI from './UI/tasks';
import job from './job/tasks';
import applyJob from './applyJob/tasks';
import positionJob from './positionJob/tasks';
import SettingTutorialJob from './SettingTutorialJob/tasks';
import configWhPartnerRegisterTypeForm from './configWhPartnerRegisterTypeForm/tasks';
import whPartnerRegister from './whPartnerRegister/tasks';
import companyJob from './companyJob/tasks';
import infoCompany from './infoCompany/tasks';
import coreValue from './coreValue/tasks';
import listSoftware from './listSoftware/tasks';
import listTrial from './listTrial/tasks';
import uiClient from './uiClient/tasks';
import jobQuestion from './jobQuestion/tasks';
import jobForm from './jobForm/tasks';
import adv from './adv/tasks';

export default function* rootSaga() {
  yield all([
    themeSaga(),
    hospitalSaga(),
    appointmentSaga(),
    degreeSaga(),
    specialitySaga(),
    geoSaga(),
    staffSaga(),
    serviceSaga(),
    newsCategorySaga(),
    newsSaga(),
    staffGroupSaga(),
    layoutSaga(),
    branchSaga(),
    employeeSaga(),
    orderSaga(),
    employeeGroupSaga(),
    partnerSaga(),
    userEmployeeSaga(),
    partnershipSaga(),
    couponSaga(),
    policySaga(),
    dashboardSaga(),
    shippingVendorSaga(),
    whBillSaga(),
    whCategorySaga(),
    whPackageLevelSaga(),
    whServiceSaga(),
    whSessionOfDay(),
    whPartner(),
    whReceiptVoucher(),
    whPaymentVoucher(),
    whBillItem(),
    whAppointment(),
    rateConfig(),
    customerType(),
    whCustomerManagement(),
    whCustomerImport(),
    importWhCustomerExcel(),
    evaluate(),
    systemConfiguration(),
    notificationBotManagement(),
    whBanner(),
    whCategoryShort(),
    Quotation(),
    whPartnerImport(),
    trainingCourse(),
    trainingCoursePartner(),
    whWorkNorm(),
    timeSheet(),
    configCampaign(),
    rewardItem(),
    pointSystem(),
    tipsPartner(),
    reportSystem(),
    whReportCustomer(),
    whMedicine(),
    whReportMedicines(),
    whHighlight(),
    transactions(),
    appointmentAssociate(),
    giveCoupon(),//
    ranking(),
    hospitalListSaga(),
    servicePlaceSaga(),
    bonusIntroCustomer(),
    exchangePoint(),
    activityReferral(),//
    requestVoucher(),//
    wallet(),//
    whAllVouchers(),
    partnerCancelAppointment(),
    clinicDashboardSaga(),
    pharmacyOnlineDashboardSaga(),
    keywordSearch(),//ss
    percentChangeCustomerToPartner(),//
    medicalEquipment(),
    productCatalogue(),
    productList(),
    warehouseVoucher(),
    whContact(),
    confirmVoucherWarehouse(),//
    unConfirmWhBill(),//
    history(),//
    watchWareHouseList(), //
    workList(),
    workListTask(),
    myNotification(),//
    deliveryVoucher(), //

    introUsingRefCode(),//
    system(),//
    workSprintTask(),
    statusConfig(),
    groupCategory(),
    UI(),
    job(),
    applyJob(),
    positionJob(),
    SettingTutorialJob(),
    configWhPartnerRegisterTypeForm(),
    whPartnerRegister(),
    companyJob(),
    infoCompany(),
    coreValue(),
    listSoftware(),
    listTrial(),
    uiClient(),
    jobQuestion(),
    jobForm(),
    adv(),
  ]);
}
