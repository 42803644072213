import { concat, forIn, get } from 'lodash';

const COMPANY = 'company';
const PARTNERCLINIC = 'partnerClinic';
const BRANCH = 'branch';
const COMPANY_INFO = 'companyInfo';
const EMPLOYEE = 'employee';
const USER = 'user';
const USER_GROUP = 'userGroup';
const CUSTOMER = 'customer';
const PATIENT = 'patient';
const ORDER = 'order';
const APPOINTMENT = 'appointment';
const MANAGER_APPOINMENT = 'managerAppointment';
const CMS = 'cms';
const SETTINGS = 'settings';
const RATE_CONFIG = 'rateConfig';
const WH_BILL = 'whBill';
const QUOTATIONS = 'quotations';
const WH_BILL_ITEM = 'whBillItem';
const WH_APPOINTMENT = 'whAppointment';
const WH_SERVICE = 'whService';
const WH_CATEGORY = 'whCategory';
const WH_SESSION_OF_DAY = 'whSessionOfDay';
const WH_PARTNER = 'whPartner';
const WH_SETTING = 'whSetting';
const WH_UPDATE_BILL_STATUS = 'whUpdateBillStatus';
const WH_HISTORY_LOG = 'whHistoryLog';
const WH_UPDATE_RECEIPT_AND_PAYMENT_VOUCHER_STATUS =
  'whUpdateReceiptAndPaymentVoucherStatus';
// cosnt WH_BANNER
const WH_PACKAGE_LEVEL = 'whPackageLevel';
const WH_BANNER = 'banner';
const WH_POTENTIALPARTNER = 'potentialPartner';
// WH-Training course
const WH_TRAINING_COURSE = 'whCourseTraining';

const WH_POTENTIALCUSTOMER = 'potentialCustomer';

// const CUSTOMER_MANAGEMENT = 'customerManagement';
// WH-report
const WH_REVENUE_PARTNER = 'whRevenuePartner';
const WH_REVENUE_CUSTOMER = 'whRevenueCustomer';
const WH_TRANSACTION = 'whTransaction';

const WH_REVENUE_SYSTEM = 'whRevenueSystem';
// whMedicalEquipment
const WH_MEDICAL_EQUIPPMENT = 'whMedicalEquipment';

// whTips
const WHTIPS = 'whTips';
const WH_CLINIC = 'managerClinic';
const ASSOCIATE_APPOINMENT = 'affiliatedAppointment';
const PHARMACY = 'pharmacy';
//Sổ quỹ
const WHVOUCHERS = 'whVouchers';

//Quỹ đầu kỳ
const WH_BEGINNINFUND = 'whbeginninFund';

// Danh sách bệnh viện
const WH_HEALTH_CARE_FACILITY = 'whHealthcareFacility';
// Quản lý kho
const WAREHOUSE = 'warehouse';

// Quản lý kho_ danh mục sản phẩm
const WH_PRODUCT_PORT_FOLIO_WAREHOUSE = 'whProductPortfolio';

// Quản lý kho_ danh sách sản phẩm
const WH_PRODUCT_LIST_WAREHOUSE = 'whProductPortfolio';

// Yêu cầu huỷ cuộc hẹn
const WH_APPOINTMENTCANCELPARTNER = "whappointmentCancelPartner"
// REFERRAL
const REFERRAL = 'referral';

// Dashboard of WorldHealth
const WH_CHART_DASHBOARD_HEALTHCARE = "whChartDashboardHealthCare";
// Yêu cầu rút tiền
const WH_REQUESTVOUCHER = 'whRequestVoucher';

// Quản lý từ khoá tìm kiếm
const KEYWORDSEARCH = 'keywordSearch';

// give couple
const GIVECOUPON = 'giveCoupon';

const CHART_CLINIC = "chartClinic";


//WhContact
const WHCONTACT = 'whContact'


// Quản lý thông báo tự động
const NOTIFICATIONBOTMANAGER = 'notificationBotManager';


// Quan li Chart CLINIC (BecareFull Duplicate)
// GROUP WH SETTING //
const WHSERVICEGROUP = 'whServiceGroup'; // [WH] Nhóm dịch vụ
const WHSERVICE = 'whService'; // [WH] dịch vụ
const WHTIMEREGULATION = 'whTimeRegulation'; // [WH] Quy định thời gian
const WHCREDENTIAL = 'whCredential'; // [WH] Bằng cấp
const WHPOSITION = 'whPosition'; // [WH] Chức vụ
const WHEXPERIENCE = 'whExperience'; // [WH] Kinh nghiệm'
const WHPROFESSIONALSTAFF = 'whProfessionalStaff'; // [WH] Cán bộ chuyên môn'
const WHJOBSTANDARD = 'whJobStandard'; // [WH] Định mức công việc
const WHCUSTOMEREVALUATION = 'whCustomerEvaluation'; // [WH] Khách hàng đánh giá
const WHSERVICEBOOKINGINSTRUCTIONSSETUP = 'whServiceBookingInstructionsSetup'; // [WH] Setup hướng dẫn đặt dịch vụ
const SYSTEM = 'system'; // [WH] Setup hướng dẫn đặt dịch vụ

// GROUP CMS //
const MEDICALCLINIC = 'medicalClinic'; // Phòng khám
const ANNOUNCEMENT = 'announcement'; // Tin tức
const CONFIGURATION = 'configuration'; // Thiết lập
const LEADINGPARTNER = 'leadingPartner'; // Đối tác hàng đầu
const DISCOUNTCODE = 'discountCode'; // Mã giảm giá
const POLICY = 'policy'; // Chính sách
const SHIPPINGCOMPANY = 'shippingCompany'; // Nhà vận chuyển
const HIGHLIGHT = 'highlight'; // Nhà vận chuyển
const PRODUCTDELIVERY = 'productDelivery' //
const TODOLIST = 'todoList'; // Quản lý công việc (Because conflict)
const TODOCONFIGSTATUS = 'todoConfigStatus'; // Quản lý cấu hình trạng thái công việc (Because conflict)
const HISTORY = 'history';

const SERVICEBRANCH = 'serviceBranch' //
const ACCEPTSERVICE = 'acceptService' //

const WH_HISTORY_LOG_VOUCHER = 'whHistoryLogVoucher' //

// Nhóm dịch vụ người dùng
const USER_GROUP_SERVICE = 'userGroupService';

// Tuyển dụng
const JOB = 'job';

// Đăng ký đối tác
const configWhPartnerRegister = 'configWhPartnerRegister';

const SOFTWARE = 'software';

const SOFTWARETRIAL = 'softwareTrial';

const resourcePartner = 'resourcePartner';
const resourceClinic = 'resourceClinic';


const RESOURCES = [
  COMPANY,
  PARTNERCLINIC,
  BRANCH,
  COMPANY_INFO,
  EMPLOYEE,
  USER,
  USER_GROUP,
  CUSTOMER,
  PATIENT,
  ORDER,
  APPOINTMENT,
  MANAGER_APPOINMENT,
  RATE_CONFIG,
  CMS,
  SETTINGS,
  WH_BILL,
  WH_BILL_ITEM,
  WH_APPOINTMENT,
  WH_SERVICE,
  WH_CATEGORY,
  WH_SESSION_OF_DAY,
  WH_PARTNER,
  WH_POTENTIALPARTNER,
  WH_SETTING,
  WH_UPDATE_BILL_STATUS,
  WH_HISTORY_LOG,
  WH_UPDATE_RECEIPT_AND_PAYMENT_VOUCHER_STATUS,
  WH_PACKAGE_LEVEL,
  QUOTATIONS,
  WH_BANNER,
  WH_TRAINING_COURSE,
  WH_POTENTIALCUSTOMER,
  WH_REVENUE_PARTNER,
  WH_REVENUE_CUSTOMER,
  WH_TRANSACTION,
  WH_REVENUE_SYSTEM,
  WH_MEDICAL_EQUIPPMENT,
  WHTIPS,
  WH_CLINIC,
  ASSOCIATE_APPOINMENT,
  PHARMACY,
  GIVECOUPON,
  WHVOUCHERS,
  WH_BEGINNINFUND,
  WH_HEALTH_CARE_FACILITY,
  WAREHOUSE,
  WH_PRODUCT_PORT_FOLIO_WAREHOUSE,
  WH_PRODUCT_LIST_WAREHOUSE,
  KEYWORDSEARCH,
  WH_APPOINTMENTCANCELPARTNER,
  REFERRAL,
  WH_REQUESTVOUCHER,
  NOTIFICATIONBOTMANAGER,
  WH_CHART_DASHBOARD_HEALTHCARE, // (BecareFull Duplicate)
  CHART_CLINIC, // (BecareFull Duplicate)
  WHCONTACT, // (BecareFull Duplicate)
  WHSERVICEGROUP,
  WHSERVICE,
  WHTIMEREGULATION,
  WHCREDENTIAL,
  WHPOSITION,
  WHEXPERIENCE,
  WHPROFESSIONALSTAFF,
  WHJOBSTANDARD,
  WHCUSTOMEREVALUATION,
  WHSERVICEBOOKINGINSTRUCTIONSSETUP,
  MEDICALCLINIC,
  ANNOUNCEMENT,
  CONFIGURATION,
  LEADINGPARTNER,
  DISCOUNTCODE,
  POLICY,
  SHIPPINGCOMPANY,
  HIGHLIGHT,
  HISTORY,
  SERVICEBRANCH, // 
  ACCEPTSERVICE,
  PRODUCTDELIVERY,

  SYSTEM,
  TODOLIST, // (Because conflict)
  TODOCONFIGSTATUS,
  WH_HISTORY_LOG_VOUCHER,
  USER_GROUP_SERVICE,
  JOB,
  configWhPartnerRegister,
  SOFTWARE,
  SOFTWARETRIAL,
  resourcePartner,
  resourceClinic,
];

//ACTIONS
const READ = 'read';
const WRITE = 'write';
const UPDATE = 'update';
const DELETE = 'delete';
const DOWNLOAD = 'download';
const ADMIN = 'admin';


export const ACTIONS = [READ, WRITE, UPDATE, DELETE, DOWNLOAD, ADMIN];

const POLICIES = RESOURCES.reduce((policies, resource) => {
  const policy = ACTIONS.reduce(
    (actions, action) => ({
      ...actions,
      [`${action.toUpperCase()}_${resource.toUpperCase()}`]: [resource, action]
    }),
    {
      [resource.toUpperCase()]: [resource]
    }
  );

  return {
    ...policies,
    ...policy
  };
}, {});
export default POLICIES;
const RESOURCE = () => {
  const MANAGE_CUSTOMER = [
    // Khách hàng
    CUSTOMER,
    WH_POTENTIALCUSTOMER,
    WHCONTACT
  ];
  const MANAGE_USER = [
    USER, // Người dùng
    USER_GROUP
  ];
  const MANAGE_GROUP_SOFTWARE = [
    SOFTWARE,
    SOFTWARETRIAL,
  ]
  const MANAGE_CMS = [
    CMS,
    GIVECOUPON, // Chương trình khuyến mãi
    WH_BANNER, // BANNER
    WH_PACKAGE_LEVEL, // GÓI Dịch vụ
    NOTIFICATIONBOTMANAGER, // BOT thông báo
    KEYWORDSEARCH, // Từ khoá tìm kiếm
    MEDICALCLINIC,
    ANNOUNCEMENT,
    CONFIGURATION,
    LEADINGPARTNER,
    DISCOUNTCODE,
    POLICY,
    SHIPPINGCOMPANY,
    HIGHLIGHT,
    configWhPartnerRegister,
    ...MANAGE_GROUP_SOFTWARE,
  ];
  const MANAGE_WHBILL = [
    WH_BILL,
    QUOTATIONS,
    // WH_APPOINTMENT,
    WH_APPOINTMENTCANCELPARTNER
  ];
  const MANAGE_WHPARTNER = [WH_PARTNER, WH_POTENTIALPARTNER];
  const MANAGE_WHVOUCHER = [
    WHVOUCHERS,
    WH_REVENUE_PARTNER,
    WH_REVENUE_CUSTOMER,
    WH_REVENUE_SYSTEM,
    WH_TRANSACTION,
    WH_REQUESTVOUCHER,
  ];
  const MANAGE_WHSETTING = [
    WH_HEALTH_CARE_FACILITY,
    WH_TRAINING_COURSE,
    WH_MEDICAL_EQUIPPMENT,
    WH_SETTING,
    WHSERVICEGROUP,
    WHSERVICE,
    WHTIMEREGULATION,
    WHCREDENTIAL,
    WHPOSITION,
    WHEXPERIENCE,
    WHPROFESSIONALSTAFF,
    WHJOBSTANDARD,
    WHCUSTOMEREVALUATION,
    WHSERVICEBOOKINGINSTRUCTIONSSETUP,
  ];

  const MANAGE_WAREHOUSE = [WAREHOUSE, PRODUCTDELIVERY];
  const MANAGE_WORLDHEALTH = concat(
    WH_CHART_DASHBOARD_HEALTHCARE,
    REFERRAL,
    MANAGE_WHBILL,
    MANAGE_WHPARTNER,
    MANAGE_WHVOUCHER,
    MANAGE_WHSETTING,
    MANAGE_WAREHOUSE,
    MANAGE_CUSTOMER,
    HISTORY,
    PRODUCTDELIVERY
  );
  const MANAGE_PHARMACY = [PHARMACY, ORDER];
  const MANAGE_CLINIC = [APPOINTMENT, PARTNERCLINIC,ASSOCIATE_APPOINMENT,CHART_CLINIC];
  const MANAGE_VOUCHER_IN_WHBILL = [
    WH_UPDATE_RECEIPT_AND_PAYMENT_VOUCHER_STATUS,
    WHVOUCHERS
  ];
  const MANAGE_BRANCH = [
    BRANCH,
    SERVICEBRANCH
  ];

  const MANAGE_SYSTEM = [
    SYSTEM,
  ]

  const MANAGE_TODO = [
    TODOLIST,
    TODOCONFIGSTATUS
  ]
  return {
    MANAGE_CUSTOMER,
    MANAGE_USER,
    MANAGE_CMS,
    MANAGE_WHBILL,
    MANAGE_WHPARTNER,
    MANAGE_WHVOUCHER,
    MANAGE_WHSETTING,
    MANAGE_WORLDHEALTH,
    MANAGE_CLINIC,
    MANAGE_PHARMACY,
    MANAGE_VOUCHER_IN_WHBILL,
    MANAGE_BRANCH,
    MANAGE_WAREHOUSE,
    MANAGE_BRANCH,
    MANAGE_SYSTEM,
    MANAGE_TODO,
    MANAGE_GROUP_SOFTWARE,
  };
};


/**
 *
 * @param {String} action CORE_ACTION [READ, WRITE, UPDATE, DELETE]
 * @returns {Array} [POLICIES.action_resources]
 */
export const GROUP_POLICY = (action) =>
  forIn(RESOURCE(), (value, key, object) => {
    object[key] = [];
    value?.forEach(
      (keyPermission) =>
        keyPermission &&
        object[key]?.push(
          get(POLICIES, `${action}_${keyPermission?.toUpperCase()}`)
        )
    );
  });

export const CORE_ACTION = {
  READ: 'READ',
  WRITE: 'WRITE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  ADMIN: 'ADMIN',
};

export const CORE_ACTION_VI = {
  READ: 'Đọc',
  WRITE: 'Thêm mới',
  UPDATE: 'Chỉnh sửa',
  DELETE: 'Xoá',
  DOWNLOAD: 'Tải về',
  ADMIN: 'Quản trị',
};

export const ACTION = Object.keys(CORE_ACTION_VI).map((key) => ({
  key,
  name : CORE_ACTION_VI[key]
}));
