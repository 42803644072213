import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tooltip,
  TreeSelect,
  Typography
} from 'antd';
import { compact, get, isArray, omit, uniq, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '~/api';
import { BaseBorderBox, GeoTreeSelect } from '~/components/Common';
import { MAX_PAGINATION_LIMIT, RELATIVE_POSITION, ROOT_COMPANYID } from '~/constants/defaultValue';
import { useCreateWhServiceBranch, useResetWhServiceBranch, useUser, useWhCategories } from '~/hooks';

const ServiceGroup = ({
  category,
  data = [],
  setData,
  onSetServiceSelect,
  onRemoveServiceSelectAll,
  disabled,
  infoService
}) => {
  const [branchId] = useUser();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState([]);

  const onCheck = useCallback(
    (e, item) => {
      const newId = get(item, '_id');
      const isChecked = e.target.checked;
      if (isChecked) {
        setData(data?.concat(newId));
        onSetServiceSelect(item);
      } else {
        setData(data?.filter((id) => id !== newId));
        onRemoveServiceSelectAll(item);
      }
    },
    [data, onRemoveServiceSelectAll, onSetServiceSelect, setData]
  );

  useEffect(() => {
    const fetchService = async () => {
      try {
        setLoading(true);
        const res = await api.whService.getWhServiceAvailableByBranch({
          status: 'ACTIVE',
          branchId: parseInt(branchId),
          whCategoryId: get(category, '_id')
        });
        setService(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    category && fetchService();
  }, [branchId, category]);

  const onSelectAll = () => {
    const ids = service?.map((item) => get(item, '_id'));
    setData(uniq(compact(data?.concat(ids))));
    onSetServiceSelect(service);
  };

  const onRemoveAll = () => {
    const ids = service?.map((item) => get(item, '_id'));
    setData(data?.filter((id) => !ids.includes(id)));
    onRemoveServiceSelectAll(service);
  };

  const dataInCategory = useMemo(
    () => service?.filter((item) => data?.includes(get(item, '_id'))),
    [service, data]
  );

  return (
    <div className="ServiceGroup">
      <Row align="middle" className="my-2" disabled = {!!infoService}>
        <Col span={12}>
          <Typography.Text className="mx-1" strong>
            {get(category, 'name.vi')}
          </Typography.Text>
          <p>
            Đã chọn: {dataInCategory?.length}/{service?.length}
          </p>
        </Col>
        <Col flex={1}>
          <Button
            disabled={!!infoService ?? disabled ?? loading}
            onClick={onSelectAll}
            className="d-flex align-items-center"
            icon={<CheckCircleOutlined />}
            type="primary"
          >
            Chọn tất cả
          </Button>
        </Col>
        <Col flex={1}>
          <Button
            disabled={!!infoService ?? disabled ?? loading}
            onClick={onRemoveAll}
            className="d-flex align-items-center"
            icon={<CloseCircleOutlined />}
            danger
          >
            Bỏ chọn tất cả
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Spin spinning />
      ) : (
        <Row>
          {service?.map((item) => (
            <Col className="mb-1" span={24}>
              <Checkbox 
                onChange={(e) => onCheck(e, item)}
                checked={data?.includes(get(item, '_id'))}
                disabled={!!infoService ?? disabled}
              >
                {get(item, 'code')} - {get(item, 'name.vi')}
              </Checkbox>
            </Col>
          ))}
          {!service?.length && (
            <Typography.Text strong>Không có dịch vụ</Typography.Text>
          )}
        </Row>
      )}
    </div>
  );
};

// MAIN COMPONENT
export default function RegisterServiceSelect({
  // defaultData,
  onCancel,
  branches,
  infoService,
  updateWhService
}) {
  useResetWhServiceBranch();
  const [servicesSelected, setServicesSelected] = useState([]);
  const [categorySelectId, setCategorySelectId] = useState();
  const queryGetCategories = useMemo(
    () => ({ page: 1, limit: MAX_PAGINATION_LIMIT }),
    []
  );
  const [categories, isLoadingCate] = useWhCategories(queryGetCategories);
  const [isLoadingServiceBranch, createService] = useCreateWhServiceBranch(onCancel);
  const [branchIdLocal, token] = useUser();
  const [branchId, setBranchId] = useState(null);
  const listBranch = useMemo(() => branches?.filter((item) =>  get(item, '_id') !== ROOT_COMPANYID), []);
  const optionsCategory = useMemo(
    () =>
      categories
        ?.filter((item) => get(item, 'status') === 'ACTIVE')
        ?.map((item) => ({
          label: get(item, 'name.vi'),
          value: get(item, '_id')
        })),
    [categories]
  );
  const [data, setData] = useState([]);
  const [areaAvailble, setAreaAvailble] = useState([]);
  

  useEffect(() => {
    if (infoService) {
      setBranchId(infoService?.branchId);
      setAreaAvailble(infoService?.areaAvailble);
      setData([infoService?.service?._id]);
      setServicesSelected([infoService?.service]);
      setCategorySelectId(infoService?.service?.whCategoryId);
    };
  }, [infoService]);

  useEffect(() => {
    if (!categorySelectId && categories?.length) {
      const newCategory = categories?.filter((item) => get(item, 'status') === 'ACTIVE');
      setCategorySelectId(get(newCategory, '[0]._id'));
    };
  }, [categories, categorySelectId]);

  // useEffect(() => {
  //     if (defaultData && defaultData?.length) {
  //         setServicesSelected(defaultData);
  //     }
  // }, [defaultData]);
  
  const categorySelect = useMemo(
    () => categories?.find((item) => get(item, '_id') === categorySelectId),
    [categorySelectId, categories]
  );

  const onSetServiceSelect = useCallback(
    (newServices) => {
      setServicesSelected(
        uniqBy(compact(servicesSelected?.concat(newServices)), '_id')
      );
    },
    [servicesSelected]
  );

  const onRemoveServiceSelectAll = useCallback(
    (newServices) => {
      if (isArray(newServices)) {
        setServicesSelected(
          servicesSelected?.filter(
            (service) =>
              !newServices?.some(
                (item) => get(item, '_id') === get(service, '_id')
              )
          )
        );
      } else {
        setServicesSelected(
          servicesSelected?.filter(
            (service) => get(service, '_id') !== get(newServices, '_id')
          )
        );
      }
    },
    [servicesSelected]
  );
  const columns = useMemo(
    () => [
      {
        title: 'Mã dịch vụ',
        dataIndex: 'code',
        key: 'code',
        render: (item) => <Typography.Text strong>{item}</Typography.Text>
      },
      {
        title: 'Tên dịch vụ',
        dataIndex: 'name',
        key: 'name',
        render: (item) => get(item, 'vi')
      }
    ],
    []
  );
  const filterServiceByCategory = (categoryId) => {
    return (servicesSelected?.filter(
      (item) => get(item, 'whCategoryId') === categoryId
    ))};
  
  const onSubmit = () => {
    const newAddress = areaAvailble?.map((item) => {
      const newItem = {
        fullAddress: item.label,
        path: item.value
      };
      return newItem;
    });
    const body = data?.map((item) => {
      return {
        branchId: String(branchId),
        areaAvailble: newAddress ?? [],
        serviceId: item,
      };
    });
    if (infoService) {
      updateWhService({
        areaAvailble: newAddress,
        id: infoService?.serviceId
      });
    } else {
      createService({ data: body });
    };
  };
  return (
    <div className="SelectService">
      <BaseBorderBox title={'Chi nhánh'}  style = {{margin: '10px'}}>
        <Row gutter={36}>
          <Col span={12} style = {{marginTop: '10px'}}>
          <Typography.Text strong>Chọn chi nhánh được đăng ký:</Typography.Text>
            <Tooltip title= {`${!branchId ?'' : 'Khi đổi chi nhánh, tất cả các dịch vụ đã được chọn sẽ bị xoá' }`}>
              <Select
                disabled = {!!infoService}
                style={{ minWidth: 300 }}
                onChange={e => { 
                  setBranchId(e);
                  setData([]);
                  setServicesSelected([]);
                }}
                value={branchId}
              >
              {listBranch?.map((item) => (
                <Select.Option key={item?._id} value={item?._id}>{item?.name}</Select.Option>
              ))}
              </Select>
            </Tooltip>
          </Col>
          <Col flex={1}  style = {{marginTop: '10px'}}>
          <Typography.Text strong>Chọn khu vực hoạt động:</Typography.Text>
            <GeoTreeSelect
              style = {{ minWidth: 400}}
              autoClearSearchValue
              checkablePositions={[RELATIVE_POSITION.IS_CHILD]}
              // enabledValues={isRootAdmin ? null : managementPaths}
              labelInValue={true}
              listItemHeight={200}
              multiple={true}
              showCheckedStrategy={TreeSelect.SHOW_ALL}
              showEnabledValuesOnly={true}
              showSearch={true}
              onSelect={(e) => console.log(e)}
              size="large"
              treeCheckStrictly={true}
              treeCheckable={true}
              treeDefaultExpandedKeys={['1', '2', '3']}
              value={[...areaAvailble]}
              onChange={(e) => {
                setAreaAvailble(e);
              }}
              onClear={() => setAreaAvailble([])}
            />
          </Col>
        </Row>
      </BaseBorderBox>
      <h4 className="text-center">Chọn dịch vụ để đăng ký</h4>
      <div className="d-flex justify-content-center align-items-center ml-1 mb-1 gap-2">
        <Typography.Text strong>Chọn nhóm dịch vụ:</Typography.Text>
        <Select
          options={optionsCategory}
          style={{ minWidth: 300 }}
          value={categorySelectId}
          onChange={(value) => setCategorySelectId(value)}
          disabled = {!!infoService}
        />
            <Button
              style={{ marginLeft: 'auto' }}
              type="primary"
              onClick={onSubmit}
              disabled = {!branchId || !data?.length || !areaAvailble?.length}
            >
              {infoService ? 'Cập nhật' : 'Đăng ký'}
            </Button>
      </div>
      <Row gutter={16} wrap={false}>
        <Col span={12}>
          <BaseBorderBox title={'Dịch vụ sẵn sàng'}>
            <ServiceGroup
              category={categorySelect}
              data={data}
              setData={setData}
              onSetServiceSelect={onSetServiceSelect}
              onRemoveServiceSelectAll={onRemoveServiceSelectAll}
              disabled={!branchId}
              infoService={infoService}
            />
          </BaseBorderBox>
        </Col>
        <Col span={12}>
          <BaseBorderBox title={'Dịch vụ đã chọn'}>
            <Tabs type="card">
              {categories?.map(
                (category) =>
                  filterServiceByCategory(get(category, '_id'))?.length && (
                    <Tabs.TabPane
                      tab={
                        <Row>
                          <Badge
                            overflowCount={9999}
                            count={
                              filterServiceByCategory(get(category, '_id'))
                                ?.length
                            }
                          ></Badge>
                          <Typography.Text className="ml-5">
                            {get(category, 'name.vi')}
                          </Typography.Text>
                        </Row>
                      }
                      key={get(category, '_id')}
                    >
                      <Table
                        dataSource={filterServiceByCategory(
                          get(category, '_id')
                        )}
                        columns={columns}
                        pagination={{
                          showTotal: (total) => `Đã chọn ${total}`,
                          showSizeChanger: true
                        }}
                        size='small'
                      />
                    </Tabs.TabPane>
                  )
              )}
            </Tabs>
          </BaseBorderBox>
        </Col>
      </Row>
    </div>
  );
}
